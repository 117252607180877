import { SentenceTaskProps } from "../SentenceTask.type";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Flex, Modal } from "antd";
import API from "Api";
import styles from "./Conversation.module.scss";
import { useSuspenseQuery } from "@tanstack/react-query";
import AnimatedAlien from "Components/AnimatedAlien/AnimatedAlien";
import useAlienSpeak from "Components/AnimatedAlien/useAlienSpeak";
import useAlienIdle from "Components/AnimatedAlien/useAlienIdle";
import PushAudioRecorder from "./PushAudioRecorder";
import TaskPanel from "Components/TaskPanel";
import { TaskType } from "App.types";

function toArrayBuffer(buffer: any) {
  const arrayBuffer = new ArrayBuffer(buffer.length);
  const view = new Uint8Array(arrayBuffer);
  for (let i = 0; i < buffer.length; ++i) {
    view[i] = buffer[i];
  }
  return arrayBuffer;
}

const Conversation: FC<SentenceTaskProps> = ({ sentence, lesson }) => {
  const [showDialog, setShowDialog] = useState(false);
  // const [firstMessage, setFirstMessage] = useState(false);
  // const [pause, setPause] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { linkedStoryId: storyId, linkedStory } = sentence;

  const { data: topic } = useSuspenseQuery({
    queryKey: ["topic", linkedStory?.title],
    queryFn: () => (linkedStory ? API.conversation.getTopic(linkedStory.title) : undefined),
  });

  useEffect(() => {
    setShowDialog(!!topic);
  }, [topic]);

  // const [form] = Form.useForm<{ message: string }>();

  const audio = useMemo(() => new Audio(), []);

  const sendMessage = useCallback(
    (blob: Blob) => {
      if (!blob) {
        return setIsLoading(false);
      }

      const file = new File([blob], "record");

      const form = new FormData();
      form.append("file", file);
      form.append("storyId", `${storyId}`);

      setIsLoading(true);

      API.conversation
        .send(form)
        .then((data) => {
          const ab = toArrayBuffer(data.audio.data);
          const blob = new Blob([ab], { type: "audio/wav" });
          audio.src = window.URL.createObjectURL(blob);
          setIsPlaying(true);
          audio.play();
          audio.onerror = () => setIsPlaying(false);
          audio.onended = () => setIsPlaying(false);
        })
        .finally(() => setIsLoading(false));
    },
    [audio, storyId],
  );

  const { startIdle, stopIdle } = useAlienIdle();
  const { play: startSpeaking, stop: stopSpeaking } = useAlienSpeak();

  useEffect(() => {
    if (isPlaying) {
      stopIdle();
      startSpeaking();
    } else {
      stopSpeaking();
      startIdle();
    }
  }, [isPlaying, startIdle, startSpeaking, stopIdle, stopSpeaking]);

  return (
    <div className={styles.conversation}>
      <AnimatedAlien loading={isLoading} />

      {/*<Form form={form}>*/}
      {/*  <Form.Item name={"message"}>*/}
      {/*    <Input.Search autoFocus enterButton={<SendOutlined />} onSearch={sendMessage} />*/}
      {/*  </Form.Item>*/}
      {/*</Form>*/}

      <Flex justify={"center"}>
        <PushAudioRecorder setLoading={setIsLoading} setBlob={sendMessage} />
      </Flex>

      <Modal
        title={"Продолжить диалог?"}
        okText={"Да"}
        cancelText={"Начать заново"}
        onCancel={() => topic && API.conversation.deleteTopic(topic?.id).then(() => setShowDialog(false))}
        open={showDialog}
        onOk={() => setShowDialog(false)}
      />

      <TaskPanel isCompleted={true} task={TaskType.Conversation} sentId={sentence.id} lessonId={lesson.id} storyId={storyId} />
    </div>
  );
};

export default Conversation;
