import { SentenceType } from "App.types";
import { useMemo } from "react";
import { flatten, uniq } from "lodash";
import { useQuery } from "@tanstack/react-query";
import API from "Api";

export const useSpeechWords = (sentence: SentenceType | undefined): { [word: string]: string[] } => {
  const words = useMemo(
    () =>
      sentence
        ? uniq(
            [
              sentence.text,
              ...sentence.alternatives.map((alt) => alt.text),
              ...flatten(sentence?.tags.map((tag) => tag.synonyms.map((synonym) => synonym))),
            ]
              .join(" ")
              .split(" ")
              .filter((w) => w && !/[а-яА-ЯЁё]/.test(w))
              .map((w) =>
                w
                  .toLowerCase()
                  .replace(/[,.!?:]/g, "")
                  .replace(
                    /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
                    "",
                  ),
              ),
          )
        : [],
    [sentence],
  );

  const { data } = useQuery({
    enabled: !!sentence && !!words?.length,
    queryKey: ["speechWords", sentence?.id, words],
    queryFn: () => (words ? API.speech.getSpeechWords(words) : undefined),
    staleTime: Infinity,
    select: (data) =>
      data?.reduce((acc: any, cur) => ({ ...acc, [cur.word]: acc[cur.word] ? [...acc[cur.word], cur.transcript] : [cur.transcript] }), {}),
  });

  return data;
};
