import styles from "./AnimatedAlien.module.scss";
import { FC, RefObject } from "react";
import cx from "classnames";

const AnimatedAlien: FC<{ loading: boolean; alienRef?: RefObject<SVGSVGElement> }> = ({ alienRef, loading }) => {
  return (
    <div className={cx(styles.animatedAlien, { [styles.animatedAlien__loading]: loading })}>
      <svg ref={alienRef} id="alien" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 445 512">
        <path
          id={"rightHead"}
          fill={"#9cf"}
          d="M405.5,235.4c0,98.7-105.4,231.3-183,231.3S39.5,334.3,39.5,235.4,121.4,56.7,222.5,56.7s183,80.1,183,178.7Z"
        />
        <path
          fill={"#9cf"}
          id={"leftHead"}
          d="M219.9,56.7c-99.8,1.3-180.4,81-180.4,178.7s104.5,230.1,182.1,231c-8.1-95-49.7-156.7-71.2-277.5-13.2-72.5,27.2-111.5,69.5-132.3Z"
        />
        <path
          fill={"#898890"}
          id={"eRight"}
          d="M330.6,300.9c26.8-26.1,35-60.5,18.8-76.4-16.3-15.9-51.4-7.8-78.2,18.3-26.8,26.1-35.1,60.5-18.8,76.5,16.4,15.9,51.4,7.8,78.2-18.4h0Z"
        />
        <path
          fill={"#898890"}
          id={"eLeft"}
          d="M112.7,300.9c26.8,26.1,61.7,34.4,78.2,18.3,16.4-16,8.1-50.3-18.8-76.4s-61.7-34.4-78.2-18.3c-16.4,15.9-8.1,50.3,18.8,76.4Z"
        />
        <path
          fill={"#898890"}
          id={"alien_mouth"}
          d="M222.5,379.2c-8.3,0-16.4-2.6-23.3-7.8-2.8-2.2-3.3-6-1.1-8.6,2.1-2.8,6.1-3.2,9-1.1,9.1,6.9,21.8,6.9,30.9,0,2.8-2.2,6.7-1.5,9,1.1,2.1,2.8,1.7,6.7-1.1,8.6-7.1,5.2-15.4,7.8-23.4,7.8h0Z"
        />
        <path
          fill={"#75747a"}
          id={"pLeft"}
          d="M93.9,224.5c-16.4,15.9-8.1,50.3,18.8,76.4,15.7,15.3,34,24.4,49.9,26.1-1.7-.4-28.3-6.5-35.7-46.4-7.6-40.8,45.1-37.8,45.1-37.8-26.6-26-61.4-34.3-78.1-18.3h0Z"
        />
        <path
          fill={"#75747a"}
          id={"pRight"}
          d="M349.1,224.5c16.4,15.9,8.1,50.3-18.8,76.4-15.7,15.3-34,24.4-49.9,26.1,1.7-.4,28.3-6.5,35.7-46.4,7.6-40.8-45.1-37.8-45.1-37.8,26.6-26,61.7-34.3,78.1-18.3h0Z"
        />
      </svg>
    </div>
  );
};

export default AnimatedAlien;
