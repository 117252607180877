import { useMutation, useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { Breadcrumb, Button, Flex, FloatButton, Image, Progress, Skeleton, Tooltip } from "antd";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import type { FireworksHandlers } from "@fireworks-js/react";
import { Fireworks } from "@fireworks-js/react";
import API from "Api";
import { delay, findLast, flatten, round, sample, uniqBy } from "lodash";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { STATIC_URL, STORY_TASKS, TaskTypes } from "App.constants";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { ProgressType, SentenceType, StatusType, TaskType } from "App.types";
import ButtonClose from "Components/ButtonClose";
import { TaskComponents } from "Components/SentenceTask";
import GrammarPanel from "Components/GrammarPanel";
import useProgress from "Hooks/useProgress";
import { encodeS3URI, replaceTemplateInfo, replaceTemplates } from "App.helpers";
import CompleteWindow from "Components/CompleteWindow";
import StoryTask from "Components/SentenceTask/StoryTask";
import styles from "./Lesson.module.scss";
import cx from "classnames";
import { UserContext } from "App";
import { isMobile } from "react-device-detect";
import { CheckOutlined, HomeOutlined, StepBackwardOutlined, StepForwardOutlined, ZoomInOutlined } from "@ant-design/icons";
import VideoPlayer from "Components/SentenceTask/VideoPlayer";
import { useAllTasks } from "../useAllTasks";
import { useTranscripts } from "Hooks/useTranscripts";
import HintDrawer from "Components/HintDrawer";
import Dictionary from "Components/Dictionary";
import { Element, scroller } from "react-scroll";
import VideoPlayerStudio from "Components/SentenceTask/VideoPlayerStudio";
import useSentenceAudio from "Hooks/useSentenceAudio";
import parse, { domToReact } from "html-react-parser";
import { useIsBeginner } from "Hooks/useIsBeginner";
import ReportFeedback from "Components/ReportFeedback";

import "./Lesson.css";

const Lesson = () => {
  const [lessonStatus, setLessonStatus] = useState<StatusType>();
  const [activeIdx, setActiveIdx] = useState(0);
  const [taskStatus, setTaskStatus] = useState<StatusType>();
  const [isDictOpened, setDictOpened] = useState(false);
  const [isGrammarOpened, setGrammarOpened] = useState(false);
  const [tries, setTries] = useState(1);
  const [audioIndex, setAudioIndex] = useState(0);
  const [isVideoPlayed, setIsVideoPlayed] = useState(false);
  const [hintDrawerStatus, setHintDrawerStatus] = useState<"closed" | "active" | "">();
  const [infoDrawerText, setInfoDrawerText] = useState<string>();
  const [allTasks, setAllTasks] = useState<SentenceType[]>([]);

  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const { state } = useLocation();
  const { id = "", course, sentId } = useParams();

  const isBeginner = useIsBeginner();

  const user = useContext(UserContext);

  const { data: userProps } = useSuspenseQuery({
    queryKey: ["props", user?.id],
    queryFn: API.user.getProps,
    staleTime: Infinity,
  });

  const { data: lesson } = useSuspenseQuery({
    staleTime: Infinity,
    queryKey: ["lesson", id],
    queryFn: () => API.lesson.get(id),
  });

  const { data: lessons = [] } = useSuspenseQuery({
    queryKey: ["lessons", "course", course],
    queryFn: () => API.lesson.getList(course),
  });

  const { data: allLessonProg } = useSuspenseQuery({
    queryKey: ["progress", course],
    queryFn: () => API.progress.getForCourse(course),
    select: (data) => data.reduce<{ [id: number]: ProgressType }>((acc, value) => ({ ...acc, [value?.lesson?.id || 0]: value }), {}),
  });

  const lessonsOnly = useMemo(() => lessons.filter((l) => l.type !== "module" && !l.optional), [lessons]);
  const prevLesson = findLast(lessonsOnly, (l) => l.order < lesson.order);

  const isLocked =
    !user?.isAdmin &&
    prevLesson &&
    allLessonProg?.[prevLesson.id]?.status !== "completed" &&
    (allLessonProg?.[prevLesson.id]?.tries ?? 0) < 2;

  const {
    story: { type, id: storyId },
  } = lesson || { story: {} };

  const { data: sentences } = useSuspenseQuery({
    staleTime: Infinity,
    queryKey: ["sentences", storyId],
    queryFn: () => API.sentence.getListByStoryId(storyId),
  });

  const onRepeat = useCallback(() => {
    API.progress.delete(id).then(() => {
      setActiveIdx(0);
      queryClient.invalidateQueries({ queryKey: ["progress", +id] });
    });

    setLessonStatus(undefined);
  }, [id, queryClient]);

  const { showProgressModal, progress, contextHolder, refetch: refetchProgress } = useProgress({ lesson, onRepeat, hideModal: !!sentId });

  const allTasksArray = useAllTasks(sentences, state?.allTasks);

  useEffect(() => {
    const allTasksTo = allTasksArray.map((s: any) => ({
      ...s,
      isCompleted: progress?.some((pr) => pr.sentence && pr.sentence.id === s?.id && pr.type === s.task),
    }));

    const nextIdx = allTasksTo.findIndex((s) => !s.isCompleted);
    setAllTasks(allTasksTo);

    if (nextIdx !== -1) {
      // @ts-ignore
      setActiveIdx((prev) => (prev > 0 ? prev : nextIdx));
    }
  }, [allTasksArray, progress]);

  useEffect(() => {
    if (sentId && allTasks.length) {
      API.progress.delete(lesson.id).then(() => {
        const taskIdx = allTasks.findIndex((t) => `${t.id}` === sentId);
        setActiveIdx(taskIdx);

        const order = sentences.find((s) => s.id === +sentId)?.order || -1;
        const completed = allTasks.filter((s: any, idx) => (state?.allTasks ? idx < +sentId : s?.lessonOrder < order));
        Promise.all(
          completed.map((sent) =>
            API.progress.save({ lesson: { id: lesson.id }, tries: 1, type: sent?.task, sentence: { id: sent?.id ?? 0 } }),
          ),
        );

        navigate(`/course/${course}/lessons/exercise/${lesson.id}`, { replace: true });
      });
    }
  }, [allTasks, course, lesson.id, navigate, sentId, refetchProgress, state?.allTasks, sentences]);

  const activeSentence = useMemo<SentenceType | undefined>(() => allTasks[activeIdx], [allTasks, activeIdx]);

  const percent = useMemo<number>(() => {
    if (lessonStatus === StatusType.Completed) return 100;

    const tasksCount = allTasks.length;
    return round((100 / tasksCount) * (activeIdx + (taskStatus === StatusType.Completed ? 1 : 0)), 2);
  }, [lessonStatus, allTasks.length, activeIdx, taskStatus]);

  const { mutateAsync, mutate } = useMutation({
    mutationFn: API.progress.save,
    retry: 3,
  });

  const transcripts = useTranscripts({ sentence: activeSentence, audioIndex });

  const audio1 = useSentenceAudio({ sentence: activeSentence });
  const audio2 = useSentenceAudio({ sentence: activeSentence, isSecond: true });

  const audios = useMemo(() => [audio1, audio2], [audio1, audio2]);

  useEffect(() => {
    audio1?.addEventListener("play", () => audio2?.pause());
    audio2?.addEventListener("play", () => audio1?.pause());

    const setNextIdx = () => setAudioIndex((prev) => (prev === 1 ? 0 : prev + 1));

    audios.forEach((audio) => {
      audio.addEventListener("ended", setNextIdx);
      // (audio as HTMLAudioElement).addEventListener("pause", setNextIdx);
    });

    return () => audios.forEach((audio) => audio.removeEventListener("ended", setNextIdx));
  }, [activeSentence, audio1, audio2, audios]);

  const TaskComponent = activeSentence?.task && TaskComponents[activeSentence.task];

  const title = useMemo(
    () => activeSentence?.description || activeSentence?.grammar?.title || TaskTypes[activeSentence?.task || ""]?.desc,
    [activeSentence?.description, activeSentence?.grammar?.title, activeSentence?.task],
  );

  const renderTitle = useCallback(
    () =>
      parse(title, {
        replace: (node: any) => {
          if (node.name === "a") {
            return (
              <Tooltip className={styles.tooltip} title={replaceTemplates(node.attribs["href"], userProps)}>
                {domToReact(node.children)}
              </Tooltip>
            );
          }
        },
      }),
    [title, userProps],
  );

  const fireworkRef = useRef<FireworksHandlers>(null);

  const onTaskComplete = useCallback(
    (answer?: string) => {
      const answers = answer?.split("|");
      setAudioIndex(0);
      setTaskStatus(StatusType.Completed);

      setHintDrawerStatus("closed");
      if (activeSentence?.info) {
        setInfoDrawerText(replaceTemplateInfo(activeSentence.info, answers));
      }

      // if (activeSentence?.markers.includes("template")) {
      //   const propName = activeSentence?.markers.find((m) => m !== "template");
      //   const skipTasks = allTasks.filter(
      //     (s, idx) =>
      //       s.markers.includes("conditional") &&
      //       s.markers.some((m) => m.startsWith(`${propName}:`)) &&
      //       !s.markers.find((m) => answers?.some((a) => m.toLowerCase() === `${propName}:${a}`.toLowerCase())),
      //   );
      //
      //   console.log(skipTasks);
      //
      //   skipTasks.forEach((t) => mutateAsync({ sentence: { id: t.id }, lesson: { id: +id }, type: t.task }));
      //   // const skipIds = skipTasks.map((s) => s.id);
      //   setAllTasks((prev) => prev.filter((t) => !skipTasks.includes(t)));
      // }

      if (activeSentence?.markers.includes("anySelect")) {
        let nextNonCondTask = allTasks.findIndex((t, idx) => idx > activeIdx && !t.markers.includes("conditional"));
        if (nextNonCondTask === -1) nextNonCondTask = Infinity;

        const skipTasks = allTasks.filter(
          (t, idx) =>
            idx > activeIdx && idx < nextNonCondTask && t.markers.includes("conditional") && !t.markers.some((m) => answers?.includes(m)),
        );

        skipTasks.forEach((t) => mutateAsync({ sentence: { id: t.id }, lesson: { id: +id }, type: t.task }));

        setAllTasks((prev) =>
          prev.filter(
            (t, idx) =>
              idx <= activeIdx ||
              idx >= nextNonCondTask ||
              !t.markers.includes("conditional") ||
              t.markers.some((m) => answers?.includes(m)),
          ),
        );
      }

      if (tries === 1 && activeSentence?.markers.includes("fireworks")) {
        fireworkRef?.current?.start();
        delay(() => fireworkRef?.current?.waitStop(), 3000);
      }
      setTries(1);

      const result = { sentence: { id: activeSentence?.id || 0 }, answer, lesson: { id: +id }, type: activeSentence?.task, tries };
      mutateAsync(result);
      // queryClient.setQueryData(["progress", lesson.id], (data: []) => [...data, result]);

      if (activeIdx < allTasks.length - 1) {
        return mutateAsync({
          lesson: { id: +id },
          percent: round((100 / allTasks.length) * (activeIdx + 1), 2),
          status: "progress",
        });
      } else {
        const tasksWithErrors = uniqBy(progress?.filter((pr) => pr.tries !== 1), (pr) => `${pr.sentence.id}${pr.type}`).length;
        const stars = ((allTasks.length - tasksWithErrors) / allTasks.length) * 5;

        return mutateAsync({
          lesson: { id: +id },
          percent: 100,
          stars,
          status: "completed",
        });
      }
    },
    [activeIdx, activeSentence, allTasks, id, mutateAsync, progress, tries],
  );

  const onNextTask = useCallback(() => {
    fireworkRef?.current?.waitStop();
    setHintDrawerStatus("");
    setInfoDrawerText(undefined);
    setTaskStatus(StatusType.Empty);

    setAllTasks((prev) => prev.map((s) => (s.id === activeSentence?.id ? { ...s, isCompleted: true } : s)));

    if (activeIdx < allTasks.length - 1) {
      setActiveIdx((prev) => prev + 1);
    } else {
      setLessonStatus(StatusType.Completed);
    }
  }, [activeSentence?.id, activeIdx, allTasks.length]);

  const completeLesson = useCallback(() => {
    mutate({
      lesson: { id: +id },
      percent: 100,
      status: "completed",
    });

    setLessonStatus(StatusType.Completed);
  }, [id, mutate]);

  const setTry = useCallback((isHint: boolean) => {
    if (isHint) {
      setTries(0);
    } else {
      setTries((prev) => (prev ? prev + 1 : prev));
    }
  }, []);

  useEffect(() => {
    if (lessonStatus === StatusType.Completed) {
      fireworkRef?.current?.start();
      delay(() => fireworkRef?.current?.waitStop(), sample([3000, 4000, 5000]));
    }
  }, [lessonStatus]);

  useEffect(() => {
    delay(() => scroller.scrollTo("header", {}), 500);
  }, [activeSentence?.id]);

  useEffect(() => {
    let delayId = 0;
    if (activeSentence?.markers.includes("showGrammar") && !showProgressModal) {
      setGrammarOpened(true);
    } else {
      setGrammarOpened(false);
    }
    if (activeSentence?.hint && !showProgressModal) {
      delayId = delay(() => setHintDrawerStatus((prev) => (prev === "closed" ? prev : "active")), 1000);
    } else {
      clearTimeout(delayId);
      setHintDrawerStatus((prev) => (prev === "closed" ? prev : ""));
    }
    return () => (delayId ? clearTimeout(delayId) : undefined);
  }, [activeSentence?.hint, activeSentence?.markers, showProgressModal]);

  const goBack = useCallback(() => {
    setInfoDrawerText(undefined);

    if (activeSentence) {
      const reqs = [API.progress.deleteTask({ sentenceId: activeSentence.id, lessonId: lesson.id, task: activeSentence.task })];
      if (activeIdx > 0) {
        reqs.push(
          API.progress.deleteTask({ sentenceId: allTasks[activeIdx - 1].id, lessonId: lesson.id, task: allTasks[activeIdx - 1].task }),
        );
      }
      Promise.all(reqs).then(() => setActiveIdx((prev) => prev - 1));
    }
  }, [activeIdx, activeSentence, allTasks, lesson.id]);

  const goForward = useCallback(() => {
    let answer = "";
    if (activeSentence?.markers.includes("anySelect")) {
      answer = flatten(activeSentence?.alternatives.map((alt) => [alt.text, alt.translate]).filter((text) => text)).join("|");
    }
    onTaskComplete(answer);
    onNextTask();
  }, [activeSentence?.alternatives, activeSentence?.markers, onNextTask, onTaskComplete]);

  useEffect(() => {
    if (isLocked) {
      navigate("/");
    }
  }, [isLocked, navigate]);

  const mediaComponent = () => {
    const mediaAudio = activeSentence?.markers.includes("onlyAudio") && activeSentence?.mediaStory && (
      <audio controls src={`${STATIC_URL}/stories/${encodeS3URI(activeSentence.mediaStory.title)}/audio.mp3`} />
    );

    const audio = activeSentence?.media?.includes(".mp3") && (
      <audio controls src={`${STATIC_URL}/media/${encodeS3URI(activeSentence.media)}`} />
    );

    const image = (activeSentence?.media?.endsWith(".jpg") ||
      activeSentence?.media?.endsWith(".jpeg") ||
      activeSentence?.media?.endsWith(".svg") ||
      activeSentence?.media?.endsWith(".gif")) &&
      !isMobile && (
        <Image
          preview={
            !isMobile && {
              toolbarRender: () => null,
              mask: <ZoomInOutlined style={{ fontSize: 24 }} />,
              maskClassName: styles.mask,
            }
          }
          rootClassName={styles.childrenImage}
          onError={(e: any) => (e.target.style.visibility = "hidden")}
          src={`${STATIC_URL}/media/${encodeS3URI(activeSentence?.media)}`}
          // style={{ backgroundImage: `url(${STATIC_URL}/media/${activeSentence.media})` }}
          alt={""}
          height={"100%"}
        />
      );
    return mediaAudio || audio || image ? (
      <>
        {mediaAudio}
        {audio}
        {image}
      </>
    ) : null;
  };

  console.log({ allTasks, activeIdx, activeSentence });

  return (
    <div className={cx(styles.lesson, { [styles.lesson__isMobile]: isMobile })}>
      <Element name={"header"} />

      {!isMobile && (
        <Breadcrumb
          items={[
            { title: <Button type={"link"} size={"small"} icon={<HomeOutlined />} onClick={() => navigate("/")}></Button> },
            { title: <Link to={`/course/${course}`}>{course}</Link> },
            { title: lesson.story.title },
          ]}
        />
      )}

      {lessonStatus !== StatusType.Completed && (
        <div className={styles.header}>
          <Flex>
            <Progress percent={round(percent)} showInfo={false} />
            <ButtonClose path={course ? `/course/${course}` : "/"} />
          </Flex>
        </div>
      )}

      <Skeleton active={!!sentId} loading={!!sentId}>
        <SwitchTransition>
          <CSSTransition key={`${activeIdx}${progress?.length}${activeSentence?.id}`} timeout={500} classNames={"fade"}>
            <Flex vertical flex={"1 1"}>
              {lessonStatus !== StatusType.Completed &&
                isMobile &&
                // @ts-ignore
                ![TaskType.PictureSelect].includes(activeSentence?.task) &&
                activeSentence?.media && (
                  <Image
                    rootClassName={styles.childrenImage}
                    preview={false}
                    onError={(e: any) => (e.target.style.visibility = "hidden")}
                    src={`${STATIC_URL}/media/${activeSentence?.media}`}
                    // style={{ backgroundImage: `url(${STATIC_URL}/media/${activeSentence.media})` }}
                    alt={""}
                    height={"100%"}
                  />
                )}

              {lessonStatus !== StatusType.Completed &&
                activeSentence?.mediaStory &&
                activeSentence?.task !== TaskType.Video &&
                !activeSentence.markers.includes("onlyAudio") && (
                  <VideoPlayerStudio story={activeSentence.mediaStory} showSubtitles={activeSentence.markers.includes("subtitles")} />
                )}

              {lessonStatus !== StatusType.Completed &&
                !!(activeSentence?.medias?.length || activeSentence?.media?.toLowerCase().includes(".mp4")) &&
                //@ts-ignore
                ![TaskType.Video, TaskType.VideoSelect, TaskType.VideoDragDrop].includes(activeSentence.task) && (
                  <VideoPlayer
                    withFullscreen={true}
                    className={styles.video}
                    largeCaptionSize
                    showCaptions={
                      activeSentence.markers.includes("subtitles") ||
                      (activeSentence.markers.includes("subtitlesAfter") && taskStatus === StatusType.Completed)
                    }
                    medias={
                      activeSentence.medias.length
                        ? activeSentence.medias
                        : [
                            {
                              url: activeSentence.media,
                            },
                          ]
                    }
                    autoPlay={!sentId && !showProgressModal}
                    onFinish={() => setIsVideoPlayed(true)}
                  />
                )}

              <div className={styles.contentWrapper}>
                {title && activeSentence?.task !== TaskType.Intro && lessonStatus !== StatusType.Completed && (
                  <div
                    id={"title"}
                    className={styles.title}
                    //dangerouslySetInnerHTML={{ __html: title }}
                  >
                    {renderTitle()}
                  </div>
                )}

                <div
                  className={cx(styles.content, {
                    [styles.content__fullHeight]:
                      lesson.story.type === "dialog" ||
                      (activeSentence?.task && [TaskType.Grammar, TaskType.MaskedDragDrop].includes(activeSentence.task)) ||
                      activeSentence?.linkedStoryId,
                  })}
                >
                  {lessonStatus === "completed" ? (
                    <CompleteWindow sentences={sentences} onRepeat={onRepeat} lessonId={+id} course={course} />
                  ) : (
                    activeSentence &&
                    ((activeSentence?.task && STORY_TASKS.includes(activeSentence.task)) ||
                    lesson.story.type === "dialog" ||
                    (activeSentence.linkedStoryId && activeSentence.task === TaskType.Video) ? (
                      <StoryTask
                        mediaStory={activeSentence.mediaStory}
                        activeType={activeSentence.task}
                        story={activeSentence.linkedStory || lesson.story}
                        lesson={lesson}
                        onTaskComplete={onTaskComplete}
                        onNext={onNextTask}
                        media={activeSentence.media}
                        markers={activeSentence.markers}
                        autoPlay={!sentId && !showProgressModal}
                        showGrammar={(activeSentence?.grammar || lesson.grammar) && setGrammarOpened}
                      ></StoryTask>
                    ) : (
                      <TaskComponent
                        audio={audios?.[audioIndex]}
                        sentence={activeSentence}
                        transcripts={transcripts}
                        onNext={onNextTask}
                        activeType={activeSentence?.task}
                        onTaskComplete={onTaskComplete}
                        lesson={lesson}
                        setDictOpened={setDictOpened}
                        setTry={setTry}
                        showGrammar={(activeSentence?.grammar || lesson.grammar) && setGrammarOpened}
                        // alignCenter={type === "card" || activeSentence?.isCard}
                        alignCenter={true}
                        showAnswer={activeSentence?.task === TaskType.SpellerListen}
                        sentences={sentences}
                        showSuccessMessage={false}
                        autoPlay={!sentId && !showProgressModal && (!activeSentence.medias.length || isVideoPlayed)}
                        noTranslate={activeSentence?.markers.includes("noTranslate")}
                        noText={activeSentence?.markers.includes("noText")}
                        completeLesson={completeLesson}
                      >
                        {mediaComponent()}
                      </TaskComponent>
                    ))
                  )}
                </div>
              </div>
            </Flex>
          </CSSTransition>
        </SwitchTransition>
      </Skeleton>
      {contextHolder}

      <HintDrawer
        open={hintDrawerStatus === "active"}
        text={replaceTemplates(activeSentence?.hint, userProps)}
        onClose={() => setHintDrawerStatus("closed")}
      />
      <HintDrawer
        text={taskStatus === StatusType.Completed ? replaceTemplates(infoDrawerText, userProps) : undefined}
        open={!!infoDrawerText}
      >
        <Flex justify={"space-between"} align={"center"}>
          <ReportFeedback
            size={isMobile ? "middle" : undefined}
            type={"link"}
            task={activeSentence?.task}
            sentId={activeSentence?.id}
            storyId={activeSentence?.linkedStoryId || storyId}
            lessonId={lesson.id}
          />
          <Button
            size={isMobile ? "middle" : undefined}
            onClick={() => onNextTask()}
            icon={<CheckOutlined />}
            className={styles.btn_next}
            type={"primary"}
            shape={"round"}
          >
            {isBeginner ? "далее" : "next"}
          </Button>
        </Flex>
      </HintDrawer>

      <GrammarPanel isOpen={isGrammarOpened} grammar={activeSentence?.grammar || lesson?.grammar} toggle={setGrammarOpened} />
      <Fireworks
        ref={fireworkRef}
        autostart={false}
        options={{ acceleration: 1 }}
        style={{ position: "fixed", height: "100%", width: "100%", left: 0, top: 0, pointerEvents: "none", zIndex: "9" }}
      />

      {!activeSentence?.linkedStoryId && (
        <Dictionary
          isOpen={isDictOpened}
          storyId={lesson.story.id}
          sentences={
            type === "card" || (activeSentence?.task && [...STORY_TASKS].includes(activeSentence.task)) ? sentences : activeSentence
          }
          toggle={setDictOpened}
        />
      )}

      {user?.isAdmin && lessonStatus !== StatusType.Completed && (
        <FloatButton.Group
          shape={"square"}
          style={{
            insetInlineEnd: 0,
            insetBlockEnd: 66,
          }}
        >
          <FloatButton
            style={{
              pointerEvents: activeIdx && activeSentence ? undefined : "none",
            }}
            icon={
              <StepBackwardOutlined
                style={{
                  color: activeIdx && activeSentence ? undefined : "gray",
                }}
              />
            }
            onClick={goBack}
          />
          <FloatButton
            style={{
              pointerEvents: activeIdx > allTasks.length - 1 ? "none" : undefined,
            }}
            icon={
              <StepForwardOutlined
                style={{
                  color: activeIdx > allTasks.length - 1 ? "gray" : undefined,
                }}
              />
            }
            onClick={goForward}
          />
        </FloatButton.Group>
      )}
    </div>
  );
};

export default Lesson;
