import { Editable, Slate, withReact } from "slate-react";
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { CustomText, StatusType, TaskType, VerbFormType } from "App.types";
import { withHistory } from "slate-history";
import { createEditor, Transforms } from "slate";
import { Button, Dropdown, Space } from "antd";
import API from "Api";
import { capitalize, flatten, isNumber, uniq, values } from "lodash";
import { getInitialConstructorSlate, isEqualText, withCustomLogic } from "App.helpers";
import { CloseOutlined, SoundFilled } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import SentenceMaskedLeaf from "./SentenceLeaf";
import styles from "./SentenceTask.module.scss";
import { usePressEnter } from "./Helpers/usePressEnter";
import cx from "classnames";
import { SentenceTaskProps } from "./SentenceTask.type";
import { useAudioTranscript } from "./Helpers/useAudioTranscript";
import { successMessage } from "App.constants";
import TaskPanel from "Components/TaskPanel";
import { NotifyContext } from "App";

const initialValue: any[] = [{ children: [{ text: "" }] }];

const ConstructorTask: FC<SentenceTaskProps> = ({
  sentence,
  sentence: { text, tags, translate, options, id, storyId },
  lesson,
  onTaskComplete,
  onNext,
  children,
  audio,
  setDictOpened,
  showSuccessMessage = true,
  setTry,
  showGrammar,
  autoHeight,
  transcripts,
  noTranslate,
}) => {
  const [status, setStatus] = useState<StatusType>(StatusType.Empty);
  const [activeLeaf, setActiveLeaf] = useState<number>();
  const [activeIdx, setActiveIdx] = useState<number | null>(null);

  const editor = useMemo(() => withReact(withHistory(withCustomLogic(createEditor()))), []);
  const notifyApi = useContext(NotifyContext);

  const { data: wordGroups = [] } = useQuery({
    queryKey: options ? ["wordGroups", ...options] : [],
    queryFn: () => API.wordGroup.gelList(options),
    staleTime: Infinity,
    enabled: !!options?.length,
  });

  const lemmas = useMemo<any>(() => {
    const r = options?.map((groupId, idx) => {
      const wg: any = wordGroups.find((wg) => wg.id === groupId);
      return wg?.system ? tags[idx].lemma?.toLowerCase() : wg?.withForms ? wg.words : [];
    });
    return flatten(r);
  }, [tags, wordGroups, options]);

  const { data: verbForms = [] } = useQuery({
    queryKey: ["verbForms", ...lemmas],
    queryFn: () => API.wordGroup.getForms(lemmas),
    staleTime: Infinity,
    enabled: !!lemmas.length,
  });

  const verbsAsOptions = useMemo<{ [verb: string]: VerbFormType }>(
    () => verbForms?.reduce((acc, cur) => ({ ...acc, [cur.base]: cur }), {}),
    [verbForms],
  );

  const wordsToSelect = useMemo(() => {
    if (activeIdx === null || activeIdx < 0) return [];
    const wordGroup = wordGroups.find((el) => el.id === options?.[activeIdx]);

    if (wordGroup?.system) {
      const baseForm = tags[activeIdx].lemma.toLowerCase();

      if (wordGroup.title === "-es, -s") {
        return [{ word: baseForm, options: [verbsAsOptions?.[baseForm]?.base, verbsAsOptions?.[baseForm]?.es] }];
      }
      if (wordGroup.title === "-es, -s + V2") {
        return [
          { word: baseForm, options: [verbsAsOptions?.[baseForm]?.base, verbsAsOptions?.[baseForm]?.es, verbsAsOptions?.[baseForm]?.v2] },
        ];
      }
      if (wordGroup.title === "v1_v2") {
        return [{ word: baseForm, options: uniq([verbsAsOptions?.[baseForm]?.base, verbsAsOptions?.[baseForm]?.v2]) }];
      }
      if (wordGroup.title === "v1_v2_v3_v4") {
        return [
          {
            word: baseForm,
            options: uniq([
              verbsAsOptions?.[baseForm]?.base,
              verbsAsOptions?.[baseForm]?.v2,
              verbsAsOptions?.[baseForm]?.v3,
              verbsAsOptions?.[baseForm]?.es,
            ]),
          },
        ];
      }
      if (wordGroup.title === "v1_v2_v3_v4_v5") {
        return [
          {
            word: baseForm,
            options: uniq([
              verbsAsOptions?.[baseForm]?.base,
              verbsAsOptions?.[baseForm]?.v2,
              verbsAsOptions?.[baseForm]?.v3,
              verbsAsOptions?.[baseForm]?.es,
              verbsAsOptions?.[baseForm]?.ing,
            ]),
          },
        ];
      }

      return [{ word: baseForm, options: uniq(values(verbsAsOptions?.[baseForm]).filter((v) => v)) }];
    } else if (wordGroup?.withForms) {
      return wordGroup.words.map((word) => ({ word, options: uniq(values(verbsAsOptions?.[word])) }));
    }
    return [{ word: "", options: wordGroup?.words }];
  }, [wordGroups, options, activeIdx, tags, verbsAsOptions]);

  const onCheck = useCallback(
    (textAnswer: string = "", isHintClicked: boolean = false): StatusType => {
      const userAnswerText = textAnswer || editor.children[0]?.children?.map((el: CustomText) => el.text).join("") || "";

      let hasErrors = false;
      editor.children[0]?.children.forEach((el: CustomText, idx: number) => {
        if (el.answer && !el.text) {
          hasErrors = true;
          Transforms.setNodes(editor, { status: "empty" }, { at: [0, idx] });
        } else if (el.answer && el.text.trim().toLowerCase() !== el.answer.trim().toLowerCase()) {
          hasErrors = true;
          Transforms.setNodes(editor, { status: "lemma" }, { at: [0, idx] });
        } else {
          Transforms.insertText(editor, el.answer || " ", { at: [0, idx] });
          Transforms.setNodes(editor, { status: "" }, { at: [0, idx] });
        }
      });

      if (hasErrors) {
        if (userAnswerText.trim() && !isHintClicked) {
          API.event.save({
            sentence: { id: sentence.id },
            text: userAnswerText,
            type: "mistake",
            task: TaskType.Collect,
            lesson: { id: lesson.id },
          });
        }

        if (!isHintClicked) {
          notifyApi.warning({
            message: "Есть ошибки",
            duration: 2,
          });

          setStatus(StatusType.Error);
          setTry();
        } else {
          setTry(true);
        }

        return StatusType.Error;
      } else {
        showSuccessMessage && notifyApi.success(successMessage);
        onTaskComplete();
        audio?.play();
        setActiveIdx(-1);
        setStatus(StatusType.Completed);
        return StatusType.Completed;
      }
    },
    [audio, editor, lesson.id, notifyApi, onTaskComplete, sentence.id, setTry, showSuccessMessage],
  );

  const setNextOptionIdx = useCallback(
    (initialIdx?: number) =>
      // @ts-ignore
      setActiveIdx((prev = 0) => (options ? options.findIndex((opt, idx) => isNumber(opt) && idx > (initialIdx ?? prev)) : -1)),
    [options],
  );

  const setInitialState = useCallback(() => {
    setNextOptionIdx(-1);

    editor.children.forEach(() => {
      Transforms.delete(editor, { at: [0] });
    });

    editor.children = [];

    Transforms.insertNodes(editor, [{ children: getInitialConstructorSlate(tags, options || [], text) }]);
  }, [setNextOptionIdx, editor, tags, options, text]);

  // initial
  useEffect(() => {
    setStatus(StatusType.Editing);
    setInitialState();
  }, [sentence.id, setInitialState, setNextOptionIdx]);

  // audio transcript
  useAudioTranscript({ audio, sentence, setActiveLeaf, transcripts });

  useEffect(() => {
    if (status === StatusType.Error) {
      const wrongIdx = options?.findIndex((opt, idx) => editor.children[0].children.some((el: any) => el.idx === idx && el.status !== ""));
      if (isNumber(wrongIdx) && wrongIdx > -1) {
        setActiveIdx(wrongIdx);
      }
    }
  }, [status, editor, options]);

  const renderLeaf = useCallback(
    (props: any) => (
      <SentenceMaskedLeaf
        showMasked
        onClick={
          props.leaf.isMasked
            ? () => {
                setActiveIdx(props.leaf.idx);
              }
            : undefined
        }
        completed={StatusType.Completed === status}
        underlined={props.leaf.audioIdx === (activeLeaf ?? -1)}
        showErrors={status === StatusType.Error}
        active={StatusType.Completed !== status && activeIdx === props.leaf.idx}
        {...props}
      />
    ),
    [activeIdx, activeLeaf, status],
  );

  const onHintAnswer = () => {
    const text = editor.children[0]?.children?.map((el: CustomText) => el.text).join("");
    API.event.save({ text, type: "hint", task: TaskType.Collect, lesson: { id: lesson.id }, sentence: { id: sentence.id } });

    const result = onCheck(text, true);

    if (result) {
      const hasError = editor.children[0]?.children.some((el: CustomText, idx: number) => {
        if (isEqualText(el.text, el.answer)) {
          return false;
        }
        Transforms.insertText(editor, el.answer, { at: [0, idx] });
        Transforms.setNodes(editor, { status: "hint" }, { at: [0, idx] });

        setNextOptionIdx(el.idx);

        return true;
      });

      if (hasError) {
        setTry(true);
      }
    }
  };

  usePressEnter({ isCompleted: status === StatusType.Completed, onNext, onCheck });

  const onCollectTagClick = (text: string) => (e: any) => {
    const word = e?.key || text;

    const idx = editor.children[0].children.findIndex((el: CustomText) => el.idx === activeIdx);

    Transforms.insertText(editor, idx === 0 ? capitalize(word) : word, { at: [0, idx] });

    setStatus(StatusType.Editing);

    if (options?.filter((opt) => isNumber(opt)).length === 1) {
      onCheck();
    } else {
      setActiveIdx((prev) => {
        const nextIdx = options?.findIndex((opt, idx) => isNumber(opt) && idx > (prev ?? 0));
        const wrongItems = editor.children[0].children.filter((el: any) => el.status !== "");

        if (nextIdx === -1 || wrongItems.length === 1) {
          onCheck();
          return prev;
        }

        return nextIdx || null;
      });
    }
  };

  return (
    <div className={styles.sentenceTask}>
      <div className={cx(styles.content, { [styles.content_hasChildren]: !!children, [styles.content_autoHeight]: autoHeight })}>
        <div className={styles.children}>{children}</div>

        <div className={cx(styles.slate, styles.slate_alignCenter)}>
          <div className={styles.slate_wrapper}>
            <form spellCheck="false">
              <Slate editor={editor} initialValue={initialValue}>
                <Editable className={styles.textArea} readOnly={true} renderLeaf={renderLeaf} />
              </Slate>
            </form>

            {status !== "completed" && (
              <Button
                size={"small"}
                type={"text"}
                className={styles.btn__clear}
                icon={<CloseOutlined style={{ fontSize: 12 }} />}
                onClick={setInitialState}
              />
            )}
          </div>
          {(!noTranslate || status === StatusType.Completed) && <div className={styles.translate}>{translate}</div>}
        </div>

        <div className={styles.bottom}>
          {status === "completed" ? (
            <Button icon={<SoundFilled />} onClick={() => audio?.play()} />
          ) : (
            <Space wrap align={"center"} className={styles.select}>
              {wordsToSelect?.map((opt) =>
                wordsToSelect.length === 1 ? (
                  opt.options?.map((word) => (
                    <Button key={word} onClick={onCollectTagClick(word)}>
                      {word}
                    </Button>
                  ))
                ) : (
                  <Dropdown.Button
                    key={opt.word}
                    onClick={onCollectTagClick(opt.word)}
                    menu={{ items: opt.options?.map((w) => ({ key: w, label: w })), onClick: onCollectTagClick(opt.word) }}
                  >
                    {opt.word}
                  </Dropdown.Button>
                ),
              )}
            </Space>
          )}
        </div>
      </div>

      <TaskPanel
        lessonId={lesson.id}
        task={TaskType.Constructor}
        sentId={id}
        storyId={storyId}
        onCheck={onCheck}
        onNext={onNext}
        isCompleted={status === StatusType.Completed}
        onHint={onHintAnswer}
        setDictOpened={setDictOpened}
        audio={audio}
        showGrammar={showGrammar}
      ></TaskPanel>
    </div>
  );
};

export default ConstructorTask;
