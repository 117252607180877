import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { StatusType, TaskType } from "App.types";
import { Dropdown, Image } from "antd";
import { delay, flatten, keys, orderBy } from "lodash";
import cx from "classnames";
import { getCompletedSlate, isEqualText, replaceTemplates } from "App.helpers";
import { SentenceTaskProps } from "../SentenceTask.type";
import styles from "./StorySelectTask.module.scss";
import TaskPanel from "Components/TaskPanel";
import { isMobile } from "react-device-detect";
import { STATIC_URL, successMessage, TagsToMerge } from "App.constants";
import useMergedTags from "Components/useMergedTags";
import Dictionary from "Components/Dictionary";
import { ZoomInOutlined } from "@ant-design/icons";
import { useSuspenseQuery } from "@tanstack/react-query";
import API from "Api";
import { NotifyContext, UserContext } from "App";

const StorySelectTask: FC<SentenceTaskProps> = ({
  onTaskComplete,
  showSuccessMessage = false,
  setTry,
  sentence,
  noTranslate,
  showGrammar,
  audio,
  onNext,
  lesson,
}) => {
  const [status, setStatus] = useState<StatusType>(StatusType.Editing);
  const [selectedAnswers, setSelectedAnswers] = useState<{ [key: number | string]: { userAnswer: string; answer: string } }>({});
  const [isDictOpened, setDictOpened] = useState(false);
  const [wrongTags, setWrongTags] = useState<string[]>([]);

  const { sentences = [] } = sentence.linkedStory || {};
  const { media } = sentence;

  const user = useContext(UserContext);

  const { data: userProps } = useSuspenseQuery({
    queryKey: ["props", user?.id],
    queryFn: API.user.getProps,
    staleTime: Infinity,
  });

  const tagsSentences = useMergedTags(sentences);

  const mergedTagsSentences = useMemo(
    () =>
      tagsSentences.map(({ tags, text, translate, ...rest }) => {
        const maskedIdx = tags.findIndex((tag) => tag.isMasked);
        const maskedAnswer = tags
          .filter((t) => t.isMasked)
          .map((t) => (TagsToMerge.includes(t.word) ? t.word : ` ${t.word}`))
          .join("")
          .trim();
        const onlyFirstMaskedTags = tags
          .filter((t, idx) => idx === maskedIdx || !t.isMasked)
          .map((t) => (t.isMasked ? { ...t, word: maskedAnswer } : { ...t, word: replaceTemplates(t.word, userProps) }));

        return {
          ...rest,
          text: replaceTemplates(text, userProps),
          translate: replaceTemplates(translate, userProps),
          tags: onlyFirstMaskedTags,
        };
      }),
    [tagsSentences, userProps],
  );

  const options = useMemo(
    () =>
      mergedTagsSentences.map(
        (s) =>
          s.wordGroup || {
            words: orderBy([...s.tags.filter((t) => t.isMasked).map((t) => t.word), ...s.alternatives.map((alt) => alt.text)]),
          },
      ),
    [mergedTagsSentences],
  );

  const notifyApi = useContext(NotifyContext);

  // const translateRef = useRef<HTMLDivElement>(null);

  const taggedSentences = useMemo<any[][]>(() => mergedTagsSentences?.map((s) => getCompletedSlate(s.tags, s.text)), [mergedTagsSentences]);

  const maskedTags = useMemo(() => flatten(mergedTagsSentences?.map((s) => s.tags.filter((t) => t.isMasked))), [mergedTagsSentences]);
  const maskedTagIds = useMemo(() => maskedTags.map((t) => `${t.id}`), [maskedTags]);

  const onCheck = useCallback(() => {
    const wrongIds = keys(selectedAnswers).filter((k) => !isEqualText(selectedAnswers[k].userAnswer, selectedAnswers[k].answer));
    const emptyIds = maskedTagIds.filter((t) => !selectedAnswers[t]);
    const errors = [...wrongIds, ...emptyIds];
    setWrongTags(errors);
    if (!errors.length) {
      setStatus(StatusType.Completed);
      onTaskComplete();
      if (showSuccessMessage) {
        notifyApi.success(successMessage);
      }
    } else {
      setTry();
      setStatus(StatusType.Error);
    }
    return errors;
  }, [selectedAnswers, maskedTagIds, onTaskComplete, showSuccessMessage, notifyApi, setTry]);

  const onHintClick = useCallback(() => {
    const errors = onCheck();
    maskedTagIds.some((id) => {
      if (errors.includes(id)) {
        setWrongTags((prev) => prev.filter((t) => t !== id));
        const tag = maskedTags.find((t) => `${t.id}` === id);
        setSelectedAnswers((prev) => ({ ...prev, [id]: { id, answer: tag?.word ?? "", userAnswer: tag?.word ?? "" } }));
        return true;
      }
      return false;
    });
  }, [onCheck, maskedTagIds, maskedTags]);

  const timeoutRef = useRef<number>();

  useEffect(() => {
    clearTimeout(timeoutRef.current);
    if (status === StatusType.Error) {
      timeoutRef.current = delay(() => setStatus(StatusType.Empty), 2000);
    }
    return () => clearTimeout(timeoutRef.current);
  }, [status]);

  return (
    <div className={cx(styles.storySelectTask, { [styles.storySelectTask__mobile]: isMobile })}>
      {[".jpg", ".svg", ".gif"].includes(media?.slice(-4) ?? "") && !isMobile && (
        <Image
          rootClassName={cx(styles.image)}
          onError={(e: any) => (e.target.style.visibility = "hidden")}
          src={`${STATIC_URL}/media/${media}`}
          // style={{ backgroundImage: `url(${STATIC_URL}/media/${activeSentence.media})` }}
          alt={media}
          preview={{
            toolbarRender: () => null,
            mask: <ZoomInOutlined style={{ fontSize: 24 }} />,
            maskClassName: styles.mask,
          }}
        />
      )}

      {media?.includes(".mp3") && <audio style={{ marginBottom: 10 }} src={`${STATIC_URL}/media/${media}`} controls />}

      <div className={styles.sentences}>
        {taggedSentences?.map((tags, sentIdx) => (
          <div key={sentIdx} className={styles.sentence}>
            <span className={styles.tags}>
              {tags.map((t, idx) =>
                t.isMasked ? (
                  <Dropdown
                    key={idx}
                    trigger={["click"]}
                    menu={{
                      onClick: ({ key }) => {
                        setWrongTags((prev) => prev.filter((id) => id !== `${t.id}`));
                        setSelectedAnswers((prev) => ({ ...prev, [t.id]: { userAnswer: key, answer: t.text, id: t.id } }));
                      },
                      items: options[sentIdx]?.words.map((w) => ({ label: w, key: w })),
                    }}
                  >
                    <span
                      className={cx(styles.dropContent, {
                        [styles.dropContent__empty]: !selectedAnswers[t.id],
                        [styles.dropContent__wrong]: wrongTags.includes(`${t.id}`),
                      })}
                    >
                      {selectedAnswers[t.id]?.userAnswer ?? "-"}
                    </span>
                  </Dropdown>
                ) : (
                  <span key={idx}>{t.text}</span>
                ),
              )}
            </span>
            {!noTranslate && <span className={styles.translate}>{replaceTemplates(sentences[sentIdx].translate, userProps)}</span>}
          </div>
        ))}
      </div>

      <div className={styles.placeholder}></div>

      <TaskPanel
        sentId={sentence.id}
        lessonId={lesson.id}
        task={TaskType.StorySelect}
        storyId={sentence.linkedStoryId}
        onCheck={onCheck}
        onNext={onNext}
        isCompleted={status === StatusType.Completed}
        onHint={onHintClick}
        setDictOpened={setDictOpened}
        audio={audio}
        showGrammar={showGrammar}
      ></TaskPanel>
      <Dictionary storyId={sentence.linkedStoryId} isOpen={isDictOpened} sentences={sentences} toggle={setDictOpened} />
    </div>
  );
};

export default StorySelectTask;
