import { delay } from "lodash";
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import SentenceComments from "Components/SentenceComments";
import { SentenceType, StatusType, TaskType } from "App.types";
import Dictionary from "Components/Dictionary";
import GrammarPanel from "Components/GrammarPanel";
import Slider from "react-slick";
import styles from "./ScrollerTask.module.scss";
import ScrollerTaskRow from "./ScrollerTaskRow";
import { SentenceTaskProps } from "../SentenceTask.type";
import ScrollerTaskTranslate from "./ScrollerTaskTranslate";
import { Button, Popconfirm, Space } from "antd";
import { CheckOutlined, PauseCircleFilled, RollbackOutlined, SoundFilled } from "@ant-design/icons";
import { isMobile } from "react-device-detect";
import cx from "classnames";
import SentenceTaskHelpButton from "../SentenceTaskHelpButton";
import RecordButton from "Components/RecordButton";
import HintDrawer from "Components/HintDrawer";
import TaskPanelBasic from "Components/TaskPanelBasic";
import ReportFeedback from "Components/ReportFeedback";
import useSentenceAudio from "Hooks/useSentenceAudio";
import useTemplatedSentences from "Components/SentenceTask/useTemplatedSentences";
import { useIsBeginner } from "Hooks/useIsBeginner";
import { Element, scroller } from "react-scroll";
import useDeepgramAlt from "Hooks/useDeepgramAlt";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ScrollerTask: FC<SentenceTaskProps & { backToLesson?: Function }> = ({
  lesson,
  onNext,
  onTaskComplete,
  sentence,
  activeType,
  backToLesson,
  showGrammar,
}) => {
  const [showComments, setShowComments] = useState(false);
  const [isDictOpened, setDictOpened] = useState(false);
  const [isGrammarOpened, setGrammarOpened] = useState(false);
  const [activeIdx, setActiveIdx] = useState(0);
  const [status, setStatus] = useState<StatusType>(StatusType.Empty);
  const [audioStatus, setAudioStatus] = useState<StatusType>(StatusType.Empty);
  const [hintDrawerStatus, setHintDrawerStatus] = useState<"closed" | "active" | "">();

  const story = sentence.linkedStory || { type: "", sentences: [], id: undefined };

  const sentences = useTemplatedSentences(story.sentences);

  const isBeginner = useIsBeginner();

  const sliderRef = useRef<Slider>(null);

  const allTasks = useMemo(() => {
    return story.type === "dialog"
      ? sentences.map((s) => ({ ...s, task: s.isLeft ? TaskType.Listen : TaskType.VoiceTranslate }))
      : sentences.map((s) => ({ ...s, task: s.tasks[0] || activeType }));
  }, [activeType, sentences, story.type]);

  const activeSentence = useMemo<SentenceType | undefined>(() => allTasks[activeIdx], [allTasks, activeIdx]);

  const {
    start,
    stop,
    reset,
    results: speechResults,
    isSpeaking,
  } = useDeepgramAlt({
    text: activeSentence?.text,
    tags: activeSentence?.tags,
    setStatus: setAudioStatus,
    smart_format: false,
    interim_results: false,
  });

  useEffect(() => {
    if (allTasks[activeIdx]?.hint) {
      setHintDrawerStatus((prev) => (prev === "closed" ? prev : "active"));
    } else {
      setHintDrawerStatus("");
    }
  }, [activeIdx, allTasks]);

  // next
  const onNextSentence = useCallback(() => {
    if ([TaskType.VoiceTranslate, TaskType.Read].includes(allTasks[activeIdx + 1]?.task)) {
      reset();
    } else {
      stop();
    }

    if (activeIdx >= allTasks.length - 1) {
      onTaskComplete();
      return setStatus(StatusType.Completed);
    }

    delay(() => setActiveIdx((prev) => prev + 1), 1000);

    activeIdx > 0 && sliderRef.current?.slickNext();
  }, [activeIdx, allTasks, onTaskComplete, reset, stop]);

  const onAudioEnd = useCallback(onNextSentence, [onNextSentence]);

  const audio = useSentenceAudio({ sentence: activeSentence });

  useEffect(() => {
    if (activeSentence?.task === TaskType.Listen && audio) {
      audio.addEventListener("ended", onAudioEnd);
    }
  }, [activeSentence?.task, audio, onAudioEnd]);

  useEffect(() => {
    if (status === StatusType.isPlaying && activeSentence?.task === TaskType.Listen) {
      delay(() => audio?.play(), 500);
    }
  }, [activeIdx, status, audio, activeSentence?.task]);

  // const onPrev = useCallback(() => {
  //   reset();
  //   setCompletedTagIdx(-1);
  //   sliderRef.current?.slickPrev();
  //   setActiveIdx((prev) => prev - 1);
  // }, [reset]);

  const onRecordClick = useCallback(() => {
    if (audioStatus === StatusType.IsRecording) {
      stop();
    } else {
      // setCompletedSpeech("");
      setAudioStatus(StatusType.Loading);
      start();
    }
  }, [audioStatus, start, stop]);

  const pausePlay = useCallback(() => {
    if (status === StatusType.isPlaying) {
      audio?.removeEventListener("ended", onAudioEnd);
      setStatus(StatusType.Empty);
    } else {
      audio?.addEventListener("ended", onAudioEnd);
      audio?.play();
      setStatus(StatusType.isPlaying);
    }
  }, [status, audio, onAudioEnd]);

  const onHintAnswer = useCallback(() => {
    reset();

    if (audio) {
      audio.play();
      // audio.onended = () => {
      //   delay(() => onNextSentence(), 2000);
      // };
    } else {
      onNextSentence();
    }
  }, [reset, audio, onNextSentence]);

  const onCheckRef = useRef<Function>(null);

  useEffect(() => {
    if (activeIdx === 1) {
      scroller.scrollTo(`${activeIdx}`, { smooth: true });
    }
  }, [activeIdx]);

  return (
    <div className={styles.scrollerTask}>
      <Slider centerPadding={"10px"} ref={sliderRef} centerMode={false} vertical infinite={false} slidesToShow={3} slidesToScroll={1}>
        {allTasks.map((t: any, idx: number) => (
          <Element key={idx} name={`${idx}`}>
            {[TaskType.Translate, TaskType.Masked].includes(t.task) && idx === activeIdx ? (
              <ScrollerTaskTranslate
                active
                key={idx}
                audio={idx === activeIdx ? audio : undefined}
                sentence={t}
                activeType={t.task}
                lesson={lesson}
                onNext={onNextSentence}
                onCheckRef={onCheckRef}
              />
            ) : (
              <ScrollerTaskRow
                speechResults={idx === activeIdx ? speechResults : undefined}
                key={idx}
                audio={idx === activeIdx ? audio : undefined}
                active={idx === activeIdx}
                sentence={t}
                activeType={t.task}
                lesson={lesson}
                onComplete={onNextSentence}
                isRecording={audioStatus === StatusType.IsRecording}
              />
            )}
          </Element>
        ))}
        <div></div>
        <div></div>
      </Slider>

      {activeSentence?.task === TaskType.Translate ? (
        <TaskPanelBasic>
          <ReportFeedback lessonId={lesson.id} sentId={activeSentence?.id} task={activeSentence?.task} storyId={story.id} />

          {backToLesson &&
            (isMobile ? (
              <Popconfirm title={"Вернуться в урок?"} onConfirm={() => backToLesson()}>
                <Button icon={<RollbackOutlined />} type={"link"} />
              </Popconfirm>
            ) : (
              <Button icon={<RollbackOutlined />} type={"link"} onClick={() => backToLesson()}>
                вернуться в урок
              </Button>
            ))}

          <Button shape={"round"} type={"primary"} onClick={() => onCheckRef.current?.()}>
            проверить
          </Button>

          <SentenceTaskHelpButton
            storyId={sentence.linkedStoryId}
            isCompleted={status === StatusType.Completed}
            openDictionary={setDictOpened}
            onHintAnswer={onHintAnswer}
            showGrammar={showGrammar}
          />
        </TaskPanelBasic>
      ) : (
        <TaskPanelBasic>
          {backToLesson ? (
            isMobile ? (
              <Popconfirm title={"Вернуться в урок?"} onConfirm={() => backToLesson()}>
                <Button icon={<RollbackOutlined />} type={"link"} />
              </Popconfirm>
            ) : (
              <Button icon={<RollbackOutlined />} type={"link"} onClick={() => backToLesson()}>
                вернуться в урок
              </Button>
            )
          ) : (
            <ReportFeedback lessonId={lesson.id} sentId={activeSentence?.id} task={activeSentence?.task} storyId={story.id} />
          )}

          {status === StatusType.Completed ? (
            <Button
              disabled={status !== StatusType.Completed}
              icon={<CheckOutlined />}
              className={cx(styles.btn_next)}
              type={"primary"}
              shape={"round"}
              onClick={() => onNext()}
            >
              {isBeginner ? "далее" : "next"}
            </Button>
          ) : activeSentence?.task === TaskType.Listen ? (
            <Button type={"primary"} icon={status === StatusType.isPlaying ? <PauseCircleFilled /> : <SoundFilled />} onClick={pausePlay} />
          ) : (
            <Space>
              <RecordButton
                onClick={onRecordClick}
                isRecording={audioStatus === "isRecording"}
                loading={audioStatus === "loading"}
                isSpeaking={isSpeaking}
              />
            </Space>
          )}

          <SentenceTaskHelpButton
            storyId={sentence.linkedStoryId}
            isCompleted={status === StatusType.Completed}
            openDictionary={setDictOpened}
            onHintAnswer={onHintAnswer}
            showGrammar={showGrammar}
          />
        </TaskPanelBasic>
      )}

      <HintDrawer text={allTasks[activeIdx]?.hint} open={hintDrawerStatus === "active"} onClose={() => setHintDrawerStatus("closed")} />
      <Dictionary storyId={lesson.storyId} isOpen={isDictOpened} sentences={sentences} toggle={setDictOpened} />
      <SentenceComments isOpen={showComments} toggle={setShowComments} sentence={activeSentence} />
      <GrammarPanel isOpen={isGrammarOpened} grammar={lesson?.grammar} toggle={setGrammarOpened} />
    </div>
  );
};

export default ScrollerTask;
