import axios from "axios";
import {
  CommentType,
  DictionaryType,
  LessonType,
  EventType,
  ProgressType,
  SentenceType,
  StoryType,
  TagType,
  TranscriptType,
  WordGroupType,
  CourseType,
  VerbFormType,
  MediaType,
  UserType,
  VocabularyType,
  FeedbackType,
  UserProp,
} from "App.types";
import { instance } from "App";
import { isNumber } from "lodash";

const API = {
  feedback: {
    save: (feedback: Partial<FeedbackType>) => instance.post("/feedback", feedback).then(({ data }) => data),
    getReports: (params: Partial<FeedbackType>) => instance.get<FeedbackType[]>("/feedback/reports", { params }).then(({ data }) => data),
  },
  media: {
    getById: (id: string | number) => instance.get<MediaType>(`/media/${id}`).then(({ data }) => data),
  },
  lang: { get: () => instance.get<"en" | "de">("").then(({ data }) => data) },
  user: {
    delete: () => instance.delete("/users").then(({ data }) => data),
    updateProp: (name: string, value: string) => instance.patch("/users/props", { name, value }).then(({ data }) => data),
    getProps: () => instance.get<UserProp[]>("/users/props").then(({ data }) => data),
    update: (user: Partial<UserType>) => instance.post("/users", user).then(({ data }) => data),
    get: () => instance.get<{ level: number }>("users").then(({ data }) => data),
    yandexCode: (code: string) => instance.get<{ level: number }>("users/yandex", { params: { code } }).then(({ data }) => data),
    getSpeechToken: () => instance.get<{ token: string; region: string }>("users/get-speech-token").then(({ data }) => data),
    getRecToken: () => instance.get<{ token: string }>("users/get-rec-token").then(({ data }) => data),
    getJwtToken: () => instance.get<{ token: string }>("users/jwt-speech").then(({ data }) => data),
    getDGToken: () =>
      instance
        .get<{ key: string; api_key_id: string; comment: string; expiration_date: string; created: string }>("users/get-dg-token")
        .then(({ data }) => data),
  },
  story: {
    getList: (type: string = "story") => instance.get<StoryType[]>(`stories/type/${type}`).then(({ data }) => data),
    getByTitle: (title: string) => instance.get<StoryType>("stories/title", { params: { title } }).then(({ data }) => data),
    get: (id: string | number) => instance.get<StoryType>(`stories/${id}`).then(({ data }) => data),
    getById: (id: string | number) => instance.get<StoryType>("stories", { params: { id } }).then(({ data }) => data),
  },
  sentence: {
    getIntros: (course: string) => instance.get<SentenceType[]>("sentences/intros", { params: { course } }).then(({ data }) => data),
    getById: (sentenceId: string | number) => instance.get(`sentences/get/${sentenceId}`).then(({ data }) => data),
    getListByStoryId: (storyId: string | number, page: string | number = "1") =>
      instance.get<SentenceType[]>(`sentences/${storyId}/${page}`).then(({ data }) => data),
  },
  dictionary: {
    findOne: (word: string, pos?: string) =>
      instance.get<DictionaryType>(`/dictionary/findOne/${word}`, { params: { pos } }).then(({ data }) => data),
    find: (word: string, pos?: string) =>
      instance.get<DictionaryType[]>(`dictionary/find/${word}`, { params: { pos } }).then(({ data }) => data),
    full: (word: string, pos?: string, tag?: string) =>
      instance.get<DictionaryType>(`dictionary/full/${word}`, { params: { pos, tag } }).then(({ data }) => data),
    audio: (word: string) => instance.get(`dictionary/audio/${word}`, { responseType: "blob" }).then((resp) => resp.data),
  },
  progress: {
    delete: (id: string | number) => instance.delete(`progress/${id}`).then(({ data }) => data),
    deleteTask: (params: { task: string | undefined; lessonId: number; sentenceId: number }) =>
      instance.delete("progress/task", { params }).then(({ data }) => data),
    getVocabularyCount: () => instance.get<number>("progress/vocabulary/count").then(({ data }) => data),
    getVocabulary: () => instance.get<VocabularyType[]>("progress/vocabulary").then(({ data }) => data),
    save: (progress: Partial<ProgressType>) => instance.post("progress", progress).then(({ data }) => data),
    saveVocabularyProgress: (words: { word: string; pos: string }[]) =>
      instance.post("progress/vocabulary", words).then(({ data }) => data),
    getForCourse: (course: string | undefined) =>
      instance.get<ProgressType[]>("progress/course", { params: { course } }).then(({ data }) => data),
    getForLesson: (lesson: number) => instance.get<ProgressType[]>("progress/lesson", { params: { lesson } }).then(({ data }) => data),
    get: (progress: Partial<ProgressType>) => instance.get<ProgressType>("progress", { params: progress }).then(({ data }) => data),
  },
  event: {
    save: (event: Partial<EventType>) => instance.post("users/event", event).then(({ data }) => data),
  },
  transcriptions: {
    getBySentId: (sentId: number) => instance.get<TranscriptType[]>("audio/transcript", { params: { sentId } }).then(({ data }) => data),
    getByStoryId: (storyId: number) => instance.get<TranscriptType[]>(`audio/transcript/${storyId}`).then(({ data }) => data),
  },
  conversation: {
    deleteTopic: (id: number) => instance.delete<{}>(`conversation/topic/${id}`).then(({ data }) => data),
    getTopic: (title: string) =>
      instance.get<{ id: number; title: string }>("conversation/topic", { params: { title } }).then(({ data }) => data),
    send: (form: FormData) => instance.post("conversation/send", form).then(({ data }) => data),
  },
  practice: {
    check: (body: { storyId: string | number; originalStoryId?: string | number; text: string }) =>
      instance.post<string>("practice/check", body).then(({ data }) => data),
    speech: (form: FormData) => instance.post<string>("practice/speech", form).then(({ data }) => data),
  },
  speech: {
    getSpeechWords: (words: string[]) =>
      instance.get<{ word: string; transcript: string }[]>("speech/words", { params: { words } }).then(({ data }) => data),
    getForSentence: (params: { sentId: number; text?: string; voice: string; style?: string }) =>
      instance.get("speech/sent", { params, responseType: "arraybuffer" }).then(({ data }) => data),
    getJson: (sentenceId: number, voice: string) =>
      instance
        .get<{ duration: number; start: number; end: number; text: string }[]>("speech/json", {
          params: { sentenceId, voice },
        })
        .then(({ data }) => data),
  },
  grammar: {
    spellCheck: (text: string) =>
      instance.get<{ word: string; s: string[] }[]>("grammar/spellcheck", { params: { text } }).then(({ data }) => data),
    getList: () => instance.get("grammar").then(({ data }) => data),
    get: (id: string) => instance.get(`grammar/${id}`).then(({ data }) => data),
    getTokens: (text: string) => instance.get("grammar/tokens", { params: { text } }).then(({ data }) => data),
  },
  wordGroup: {
    find: (word: string) => instance.get<WordGroupType>(`word-group/${word}`).then(({ data }) => data),
    gelList: (ids?: number[]) =>
      instance
        .get<WordGroupType[]>("word-group", { params: { ids: ids?.filter((el) => isNumber(el)).join(",") } })
        .then(({ data }) => data),
    getForms: (verbs: string[]) =>
      instance.get<VerbFormType[]>("word-group/verb-forms", { params: { verbs: verbs.join(",") } }).then(({ data }) => data),
  },
  course: {
    getAll: () => instance.get<[CourseType & { lessons: number[] }]>("/course/all").then(({ data }) => data),
    getList: () => instance.get<[CourseType & { lessons: LessonType[] }]>("/course/").then(({ data }) => data),
    getLessonsCount: () => instance.get<[CourseType & { lessonsCount: number }]>("/course/count").then(({ data }) => data),
  },
  lesson: {
    getList: (course?: string) => instance.get<LessonType[]>("lesson", { params: { course } }).then(({ data }) => data),
    get: (id: string | number, page: string | number | null = "") =>
      instance.get<LessonType>(`lesson/${id}`, { params: { page } }).then(({ data }) => data),
  },
  comment: {
    post: (comment: Partial<CommentType>) => instance.post("users/comment", comment).then(({ data }) => data),
    getList: (sentence: Partial<SentenceType>) =>
      instance.get<CommentType[]>("users/comments", { params: { sentence } }).then(({ data }) => data),
    getCount: (id: string | number) =>
      instance.get<number>("users/comments/count", { params: { sentence: { id } } }).then(({ data }) => data),
  },
  lemma: {
    get: (text: string) => axios.get<TagType[]>("https://api.lexoid.ru/tagger/", { params: { text } }).then(({ data }) => data),
  },
  speller: {
    get: (nodes: { text: string }[]) =>
      axios
        .get<{ code: number; pos: number; word: string; s: string[] }[][]>(
          `https://speller.yandex.net/services/spellservice.json/checkTexts?text=${nodes.map((el) => el.text).join("&text=")}`,
        )
        .then(({ data }) => data),
    checkText: (text: string) =>
      axios
        .get<{ code: number; pos: number; word: string; s: string[] }[]>(
          `https://speller.yandex.net/services/spellservice.json/checkText?text=${text}`,
        )
        .then(({ data }) => data),
  },
};

export default API;
