import cx from "classnames";
import React, { FC, PropsWithChildren } from "react";
import { Tooltip } from "antd";
import styles from "./SentenceLeaf.module.scss";
import { useQuery } from "@tanstack/react-query";
import { EmptySpaceTags, POS_TAGS, TagsToMerge } from "App.constants";
import API from "Api";

const tooltip = {
  order: "неверный порядок слов",
  typo: "написано с ошибками",
  wrong: "неверное слово",
  lemma: "неверная форма слова",
  missed: "пропущено слово",
  synonym: "допустимый вариант",
};

type Props = {
  underlined: boolean;
  showMasked: boolean;
  hideMasked: boolean;
  completed?: boolean;
  hidden?: boolean;
  wrong: boolean;
  attributes: any;
  leaf: any;
  showErrors: boolean;
  active: boolean;
  placeholder?: boolean;
  onClick?: Function;
  showTranslateTooltip?: boolean;
  wrongAsTypos?: boolean;
};

const SentenceLeaf: FC<PropsWithChildren<Props>> = ({
  showMasked,
  underlined,
  hideMasked,
  wrong,
  attributes,
  children,
  leaf,
  showErrors,
  active,
  onClick,
  completed,
  showTranslateTooltip = false,
  wrongAsTypos,
  hidden,
  placeholder,
}) => {
  const { text, editable, status, id, answer = "", lemma = "", pos, isMasked = false } = leaf;

  const { data: dict } = useQuery({
    queryKey: ["word", lemma, POS_TAGS[pos]],
    queryFn: () => API.dictionary.findOne(lemma, POS_TAGS[pos]),
    staleTime: Infinity,
    enabled: !!lemma.trim() && showTranslateTooltip && ![...EmptySpaceTags, ...TagsToMerge].includes(lemma),
    select: (data) => data?.translates?.join(", ").trim() || "",
  });

  return (
    // @ts-ignore
    <Tooltip title={dict || (showErrors && tooltip[status])}>
      <span
        {...attributes}
        spellCheck="false"
        autoCorrect="off"
        onClick={() => text.trim() && onClick?.(id, text)}
        contentEditable={editable === false ? false : undefined}
        // style={editable === false ? { userSelect: "none" } : {}}
        className={cx(styles.sentenceLeaf, {
          [styles.sentenceLeaf_clickable]: text.trim() && !!onClick,
          [styles.sentenceLeaf_masked]: showMasked && isMasked,
          [styles.sentenceLeaf_hiddenMasked]: hideMasked,
          [styles.sentenceLeaf_wrong]: showErrors && status === "wrong" && !wrongAsTypos,
          [styles.sentenceLeaf_typo]: (showErrors && status === "typo") || (showErrors && wrongAsTypos && status === "wrong"),
          [styles.sentenceLeaf_form]: showErrors && ["lemma", "article"].includes(status),
          [styles.sentenceLeaf_missed]: showErrors && (status === "missed" || (wrong && id)),
          [styles.sentenceLeaf_empty]: showErrors && status === "empty",
          [styles.sentenceLeaf_order]: showErrors && status === "order",
          [styles.sentenceLeaf_underlined]: underlined,
          [styles.sentenceLeaf_green]: showErrors && status === "hint",
          [styles.sentenceLeaf_active]: active,
          [styles.sentenceLeaf_placeholder]: placeholder && text.trim(),
          [styles.sentenceLeaf_hidden]: hidden,
        })}
        style={isMasked ? { minWidth: completed || hideMasked ? 0 : answer.length * 10 } : {}}
      >
        {children}
      </span>
    </Tooltip>
  );
};

export default SentenceLeaf;
