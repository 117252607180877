import React, { FC, useCallback, useEffect, useState } from "react";
import { StatusType, TaskType } from "App.types";
import { Button, Popconfirm } from "antd";
import { BulbTwoTone, SoundFilled } from "@ant-design/icons";
import styles from "./SentenceTask.module.scss";
import cx from "classnames";
import { SentenceTaskProps } from "./SentenceTask.type";
import TaskPanel from "Components/TaskPanel";
import RecordButton from "Components/RecordButton";
import useDeepgramAlt from "Hooks/useDeepgramAlt";
import { useSpeechCheckTrans } from "Hooks/useSpeechCheckTrans";

const VoiceTranslateTask: FC<SentenceTaskProps> = ({
  sentence,
  sentence: { translate, id, storyId, info, text, tags },
  onTaskComplete,
  onNext,
  children,
  audio,
  setDictOpened,
  showGrammar,
  alignCenter = false,
  lesson,
  noTranslate,
}) => {
  const [status, setStatus] = useState<StatusType>(StatusType.Editing);

  const {
    start,
    stop,
    results: speechResults,
    isSpeaking,
  } = useDeepgramAlt({ interim_results: tags.length === 1, tags, text, setStatus, smart_format: false });

  const { completedTagIdx, setCompletedTagIdx, activeTags, reset } = useSpeechCheckTrans({
    sentence,
    speechResults,
  });

  useEffect(() => {
    if (completedTagIdx >= 0 && completedTagIdx >= activeTags.length - 1) {
      stop();
      setStatus(StatusType.Completed);
      onTaskComplete();
      // setActiveSpeechIdx(-1);
    }
  }, [completedTagIdx, activeTags, onTaskComplete, stop]);

  const onRecordClick = () => {
    if (status === StatusType.IsRecording) {
      stop();
      reset();
    } else {
      setCompletedTagIdx(-1);
      setStatus(StatusType.Loading);
      start();
    }
  };

  const onSkip = useCallback(() => {
    onTaskComplete().then(() => !info && onNext());
  }, [info, onNext, onTaskComplete]);

  const isCompleted = status === StatusType.Completed;

  const onHint = useCallback(() => {
    setCompletedTagIdx((prev) => prev + 1);
  }, [setCompletedTagIdx]);

  return (
    <div className={styles.sentenceTask}>
      <div className={cx(styles.content, { [styles.content_hasChildren]: !!children })}>
        <div className={styles.children}>{children}</div>

        <div className={cx(styles.slate, { [styles.slate_alignCenter]: alignCenter })}>
          <div className={styles.slate_wrapper}>
            <div className={styles.textArea}>
              <div className={styles.voiceTags}>
                {activeTags.map((tag, idx) => (
                  <span
                    className={cx(styles.voiceTag, {
                      [styles.voiceTag__hint]: tag.isHint && status !== StatusType.IsRecording,
                      [styles.voiceTag__active]: !isCompleted,
                      [styles.voiceTag__completed]: idx <= completedTagIdx || isCompleted,
                      [styles.voiceTag__merged]: tag.merged,
                    })}
                    key={`${idx}${tag.word}`}
                  >
                    {tag.word}
                  </span>
                ))}
              </div>
            </div>

            {status !== "completed" && (
              <Popconfirm title={"Дать подсказку?"} onConfirm={onHint}>
                <Button size={"small"} type={"text"} className={styles.btn__clear} icon={<BulbTwoTone style={{ fontSize: 18 }} />} />
              </Popconfirm>
            )}
          </div>
          {(!noTranslate || status === StatusType.Completed) && <div className={styles.translate}>{translate}</div>}
        </div>

        <div className={styles.bottom}>
          <Button
            style={{ visibility: status !== StatusType.Completed ? "hidden" : undefined }}
            icon={<SoundFilled />}
            onClick={() => audio?.play()}
          />
        </div>
      </div>

      <TaskPanel
        lessonId={lesson.id}
        task={TaskType.VoiceTranslate}
        sentId={id}
        storyId={storyId}
        setDictOpened={setDictOpened}
        isCompleted={status === StatusType.Completed}
        onNext={onNext}
        showGrammar={showGrammar}
        audio={audio}
      >
        <span className={styles.buttons}>
          <RecordButton
            onClick={onRecordClick}
            isRecording={status === "isRecording"}
            loading={status === "loading"}
            isSpeaking={isSpeaking}
          />
          <Popconfirm title={"Не можешь говорить?"} onConfirm={onSkip}>
            <Button className={styles.btn_skip} size={"small"} type={"text"}>
              skip
            </Button>
          </Popconfirm>
        </span>
      </TaskPanel>
    </div>
  );
};

export default VoiceTranslateTask;
