import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { delay, last } from "lodash";
import { Button, Flex, Popconfirm, Segmented, Space } from "antd";
import { StatusType, TaskType } from "App.types";
import Dictionary from "Components/Dictionary";
import StoryDialogSentence from "Components/StoryDialogSentence";
import { Element, scroller } from "react-scroll";
import cx from "classnames";
import TaskPanel from "Components/TaskPanel";
import { isMobile } from "react-device-detect";
import styles from "./DialogTask.module.scss";
import { SentenceTaskProps } from "../SentenceTask.type";
import DialogTaskOptions from "./DialogTaskOptions";
import DialogTaskTranslate from "./DialogTaskTranslate";
import DialogTaskRead from "./DialogTaskRead";
import { useLocation } from "react-router-dom";
import HintDrawer from "Components/HintDrawer";
import useTemplatedSentences from "../useTemplatedSentences";
import { AudioOutlined, EditOutlined, ReloadOutlined } from "@ant-design/icons";
import useDeepgramAlt from "Hooks/useDeepgramAlt";
import useSentenceAudio from "Hooks/useSentenceAudio";
import useSentencesAudios from "Hooks/useSentencesAudios";

const DialogTask: FC<SentenceTaskProps> = ({ autoPlay = true, lesson, onTaskComplete, onNext, sentence: taskSentence, showGrammar }) => {
  const [status, setStatus] = useState<StatusType>(StatusType.Empty);
  const [audioStatus, setAudioStatus] = useState<StatusType>(StatusType.Empty);
  const [activeSentIdx, setActiveSentIdx] = useState<number>(-1);
  const [isDictOpened, setDictOpened] = useState(false);
  const [hintDrawerStatus, setHintDrawerStatus] = useState<"closed" | "active" | "">();
  const [sentModes, setSentModes] = useState<boolean[]>([]);

  const location = useLocation();

  const isReview = useMemo(() => location.pathname.includes("/review"), [location]);

  const { sentences: defSentences = [], id: storyId } = taskSentence.linkedStory || {};

  const sentences = useTemplatedSentences(defSentences);
  const availableSentences = useMemo(
    () => (isReview ? sentences : sentences.slice(0, activeSentIdx + 1)),
    [sentences, activeSentIdx, isReview],
  );

  useEffect(() => {
    if (activeSentIdx >= 0 && activeSentIdx < sentences.length) {
      scroller.scrollTo(`${activeSentIdx}`, { smooth: true, isDynamic: true });
    }
  }, [activeSentIdx, sentences.length]);

  useEffect(() => {
    if (activeSentIdx >= 0)
      setSentModes((prev) => {
        const lastMode = last(prev) ?? false;
        return [...prev, lastMode];
      });
  }, [activeSentIdx]);

  useEffect(() => {
    if (status === StatusType.Empty && autoPlay && activeSentIdx === -1) setActiveSentIdx(0);
  }, [autoPlay, status, activeSentIdx]);

  const audios = useSentencesAudios({ sentences });

  const activeSent = sentences[activeSentIdx];

  const {
    start,
    stop,
    reset: resetSpeech,
    results: speechResults,
    isSpeaking,
  } = useDeepgramAlt({
    text: [TaskType.Read, TaskType.VoiceTranslate, TaskType.Translate].includes(sentences[activeSentIdx]?.tasks[0])
      ? activeSent?.text
      : undefined,
    tags: activeSent?.tags,
    setStatus: setAudioStatus,
    interim_results: false,
  });

  useEffect(() => {
    if (![TaskType.Read, TaskType.VoiceTranslate, TaskType.Translate].includes(sentences[activeSentIdx]?.tasks[0])) {
      stop();
    }
  }, [activeSentIdx, sentences, stop]);

  const audio = useSentenceAudio({
    disabled: !activeSent?.text || (activeSent.tasks.length !== 0 && activeSent.tasks[0] !== TaskType.Listen),
    sentence: activeSent,
  });

  useEffect(() => {
    if (activeSent && activeSent.text && (activeSent.tasks.length === 0 || activeSent.tasks[0] === TaskType.Listen)) {
      audio.play();
      const fn = () => setActiveSentIdx((prev) => prev + 1);
      audio.addEventListener("ended", fn);

      return () => audio.removeEventListener("ended", fn);
    }
  }, [audio, activeSentIdx, sentences, activeSent]);

  useEffect(() => {
    if (sentences.length && activeSentIdx >= sentences.length && status !== StatusType.Completed) {
      setStatus(StatusType.Completed);
      onTaskComplete();
    }
  }, [activeSentIdx, onTaskComplete, sentences.length, status]);

  const onRecordClick = useCallback(() => {
    if (audioStatus === StatusType.IsRecording) {
      stop();
    } else {
      if (activeSentIdx === 0) {
        audio.play();
      }
      start();
    }
  }, [activeSentIdx, audio, audioStatus, start, stop]);

  useEffect(() => {
    if (availableSentences[activeSentIdx]?.hint) {
      setHintDrawerStatus("active");
    } else {
      setHintDrawerStatus(undefined);
    }
  }, [activeSentIdx, availableSentences]);

  const onSuccess = useCallback(() => {
    resetSpeech();
    delay(() => setActiveSentIdx((prev) => prev + 1), 1000);
  }, [resetSpeech]);

  return (
    <div className={cx(styles.storyTask, { [styles.storyTask_mobile]: isMobile })}>
      <div className={styles.storyTask__text}>
        <Flex vertical style={{ maxWidth: 680 }} gap={5}>
          {availableSentences.map((sentence, idx) => (
            <Element name={`${idx}`} key={sentence.id}>
              <Flex
                justify={sentence.isLeft ? "flex-start" : "flex-end"}
                style={{ marginTop: sentence.isLeft !== availableSentences[idx - 1]?.isLeft ? 5 : undefined }}
              >
                {![TaskType.MultiSelect, TaskType.VoiceTranslate, TaskType.Read, TaskType.Translate].includes(sentence.tasks[0]) ? (
                  <StoryDialogSentence
                    showTranslate={!sentence.text}
                    storyId={storyId}
                    hidden={activeSentIdx < idx && !isReview}
                    active={idx === activeSentIdx}
                    sentence={sentence}
                    audio={activeSentIdx === idx ? audio : audios[idx]}
                  />
                ) : sentence.tasks.includes(TaskType.Read) ? (
                  <DialogTaskRead
                    speechResults={speechResults}
                    onClickRecording={onRecordClick}
                    audioStatus={audioStatus}
                    sentence={sentence}
                    isSpeaking={isSpeaking}
                    onSuccess={onSuccess}
                  />
                ) : [TaskType.VoiceTranslate, TaskType.Translate].includes(sentence.tasks[0]) ? (
                  <DialogTaskTranslate
                    isWriting={sentModes[idx]}
                    lesson={lesson}
                    speechResults={idx === activeSentIdx ? speechResults : undefined}
                    onClickRecording={onRecordClick}
                    audioStatus={audioStatus}
                    sentence={sentence}
                    isSpeaking={isSpeaking}
                    onSuccess={onSuccess}
                  />
                ) : (
                  <DialogTaskOptions audio={audios[idx]} storyId={taskSentence.linkedStoryId} sentence={sentence} onSuccess={onSuccess} />
                )}
              </Flex>
            </Element>
          ))}
        </Flex>
      </div>

      <TaskPanel
        sentId={taskSentence.id}
        lessonId={lesson.id}
        task={TaskType.ActiveDialog}
        setDictOpened={setDictOpened}
        showGrammar={showGrammar}
        storyId={storyId}
        onNext={onNext}
        isCompleted={status === StatusType.Completed}
      >
        <Space>
          <Popconfirm
            title={"Начать заново?"}
            onConfirm={() => {
              resetSpeech();
              setSentModes([]);
              setActiveSentIdx(-1);
            }}
          >
            <Button icon={<ReloadOutlined />} />
          </Popconfirm>

          <Segmented
            size={"large"}
            value={sentModes[activeSentIdx]}
            onChange={(value) => {
              stop();
              setSentModes((prev) => {
                prev[activeSentIdx] = value;
                return [...prev];
              });
            }}
            options={[
              { icon: <AudioOutlined style={{ fontSize: 21 }} />, value: false },
              { value: true, icon: <EditOutlined style={{ fontSize: 21 }} /> },
            ]}
          />
        </Space>
      </TaskPanel>

      <Dictionary storyId={storyId} isOpen={isDictOpened} sentences={sentences} toggle={setDictOpened} />
      <div className={styles.placeholder} />
      <HintDrawer
        text={sentences[activeSentIdx]?.hint}
        open={hintDrawerStatus === "active"}
        onClose={() => setHintDrawerStatus("closed")}
      />
    </div>
  );
};

export default DialogTask;
