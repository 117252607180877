import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { StatusType, TaskType } from "App.types";
import { Button, Card } from "antd";
import API from "Api";
import { SoundOutlined } from "@ant-design/icons";
import styles from "./SentenceTask.module.scss";
import cx from "classnames";
import { SentenceTaskProps } from "./SentenceTask.type";
import { STATIC_URL, successMessage } from "App.constants";
import { shuffle, slice, uniq } from "lodash";
import { isMobile } from "react-device-detect";
import TaskPanel from "Components/TaskPanel";
import { NotifyContext } from "App";

const PictureSelectTask: FC<SentenceTaskProps> = ({
  sentences = [],
  sentence,
  sentence: { id, media, alternatives, storyId },
  lesson,
  onTaskComplete,
  onNext,
  audio,
  setDictOpened,
  showGrammar,
  setTry,
  showSuccessMessage,
}) => {
  const [status, setStatus] = useState<StatusType>(StatusType.Editing);
  const [selectedAnswer, setSelectedAnswer] = useState<string>();

  const options = useMemo(
    () =>
      alternatives.filter((alt) => alt.media).length
        ? alternatives.filter((alt) => alt.media).map((alt) => alt.media)
        : sentences.map((s) => s.media).filter((s) => s?.includes(".svg") || s?.includes(".jpg")),
    [sentences, alternatives],
  );

  const pictures = useMemo(
    () => shuffle(uniq(slice([sentence.media, ...options], 0, lesson.selectLimit || alternatives.length + 1 || 6))),
    [sentence.media, options, lesson.selectLimit, alternatives.length],
  );

  const notifyApi = useContext(NotifyContext);

  // initial
  useEffect(() => {
    setStatus(StatusType.Editing);
  }, []);

  const onComplete = useCallback(
    (showMessage = true) => {
      setStatus(StatusType.Completed);

      if (showMessage && showSuccessMessage) {
        notifyApi.success(successMessage);
      }

      onTaskComplete();
    },
    [onTaskComplete, notifyApi, showSuccessMessage],
  );

  const onWordGroupClick = (url?: string) => () => {
    if (url === media) {
      audio?.play();
    }
    // } else {
    //   const audio = user?.isEnglish
    //     ? new Audio(getAudioUrl(text, "uk", sentence.isCard ? undefined : "true"))
    //     : new Audio(getNonEnglishAudioUrl(text, GERMAN_VOICES[0], lesson.story.id, lesson.speechRate || "slow"));
    //   audio.play();
    // }

    if (status === StatusType.Completed) {
      return;
    }

    setSelectedAnswer(url);

    if (media === url) {
      onComplete(false);
      return StatusType.Completed;
    }

    setTry();

    setStatus(StatusType.Error);

    API.event.save({ sentence: { id: sentence.id }, type: "mistake", task: TaskType.PictureSelect, lesson: { id: lesson.id } });
    return StatusType.Error;
  };

  const onHintAnswer = () => {
    API.event.save({ type: "hint", task: TaskType.PictureSelect, lesson: { id: lesson.id }, sentence: { id: sentence.id } });

    setTry(true);

    onWordGroupClick(media)();
  };

  return (
    <div className={styles.sentenceTask}>
      <div className={cx(styles.pictures, { [styles.pictures_mobile]: isMobile })}>
        {pictures.map((url) => (
          <Card
            className={cx(styles.picture, {
              [styles.btn_red]: selectedAnswer !== media && url === selectedAnswer,
              [styles.btn_green]: status === StatusType.Completed && url === selectedAnswer,
            })}
            styles={{
              body: {
                padding: 0,
              },
            }}
            key={url}
            onClick={onWordGroupClick(url)}
            hoverable
            cover={<img className={styles.img} alt={url} src={`${STATIC_URL}/media/${url}`} />}
          />
        ))}
      </div>

      <TaskPanel
        lessonId={lesson.id}
        task={TaskType.PictureSelect}
        sentId={id}
        storyId={storyId}
        showGrammar={showGrammar}
        onNext={onNext}
        isCompleted={status === StatusType.Completed}
        setDictOpened={setDictOpened}
        audio={audio}
        onHint={onHintAnswer}
      >
        <Button type={"primary"} icon={<SoundOutlined />} shape={"round"} onClick={() => audio?.play?.()}></Button>
      </TaskPanel>
    </div>
  );
};

export default PictureSelectTask;
