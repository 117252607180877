import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { StatusType, TaskType } from "App.types";
import { Button, Space } from "antd";
import API from "Api";
import { SoundOutlined } from "@ant-design/icons";
import styles from "./SentenceTask.module.scss";
import cx from "classnames";
import { SentenceTaskProps } from "./SentenceTask.type";
import { GERMAN_VOICES, successMessage } from "App.constants";
import { getAudioUrl, getNonEnglishAudioUrl } from "App.helpers";
import { shuffle } from "lodash";
import { NotifyContext, UserContext } from "App";
import TaskPanel from "Components/TaskPanel";

const AudioSelectTask: FC<SentenceTaskProps> = ({
  sentence,
  sentence: { id, text, wordGroup, alternatives, storyId },
  lesson,
  onTaskComplete,
  onNext,
  children,
  audio,
  setDictOpened,
  showGrammar,
  setTry,
}) => {
  const [status, setStatus] = useState<StatusType>(StatusType.Editing);
  const [selectedAnswer, setSelectedAnswer] = useState<string>();

  const notifyApi = useContext(NotifyContext);

  const words = useMemo(() => {
    const alterWords = alternatives.filter((alt) => !alt.media && alt.text).map((alt) => alt.text);

    if (!wordGroup && alterWords.length) return shuffle([text, ...alterWords]);

    const filtered = shuffle(wordGroup?.words.filter((w) => w !== text));
    return [text, ...filtered.slice(0, (lesson.selectLimit ?? 6) - 1)].sort();
  }, [alternatives, wordGroup, text, lesson.selectLimit]);

  // initial
  useEffect(() => {
    setStatus(StatusType.Editing);
  }, []);

  const onComplete = useCallback(
    (showMessage = true) => {
      setStatus(StatusType.Completed);

      if (showMessage) {
        notifyApi.success(successMessage);
      }

      onTaskComplete();
    },
    [onTaskComplete, notifyApi],
  );

  const user = useContext(UserContext);

  const onWordGroupClick = (t: string) => () => {
    if (t === text) {
      audio?.play();
    } else {
      new Audio(user?.isEnglish ? getAudioUrl(t, "uk") : getNonEnglishAudioUrl(t, GERMAN_VOICES[0], lesson.story.id)).play();
    }

    if (status === StatusType.Completed) {
      return;
    }

    setSelectedAnswer(t);

    if (text === t) {
      onComplete(false);
      return StatusType.Completed;
    }

    setTry();

    setStatus(StatusType.Error);

    API.event.save({ sentence: { id: sentence.id }, text: t, type: "mistake", task: TaskType.AudioSelect, lesson: { id: lesson.id } });
    return StatusType.Error;
  };

  const onHintAnswer = () => {
    API.event.save({ text, type: "hint", task: TaskType.AudioSelect, lesson: { id: lesson.id }, sentence: { id: sentence.id } });

    setTry(true);

    onWordGroupClick(text)();
  };

  return (
    <div className={styles.sentenceTask}>
      <div className={cx(styles.content, styles.content_autoHeight)}>
        <div className={styles.children}>{children}</div>

        <div className={styles.bottom}>
          <Space wrap align={"center"} className={styles.select}>
            {words?.map((t) => (
              <Button
                className={cx({
                  [styles.btn_red]: selectedAnswer !== text && t === selectedAnswer,
                  [styles.btn_green]: selectedAnswer === text && t === selectedAnswer,
                })}
                key={t}
                onClick={onWordGroupClick(t)}
              >
                {t}
              </Button>
            ))}
          </Space>
        </div>
      </div>

      <TaskPanel
        lessonId={lesson.id}
        task={TaskType.AudioSelect}
        sentId={id}
        storyId={storyId}
        onNext={onNext}
        isCompleted={status === StatusType.Completed}
        onHint={onHintAnswer}
        setDictOpened={setDictOpened}
        audio={audio}
        showGrammar={showGrammar}
      >
        <Button type={"primary"} icon={<SoundOutlined />} shape={"round"} onClick={() => audio?.play?.()}></Button>
      </TaskPanel>
    </div>
  );
};

export default AudioSelectTask;
