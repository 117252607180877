import { SentenceType, TaskType } from "App.types";
import { shuffle } from "lodash";
import { useMemo } from "react";

export const useTrainingTasks = (sentences: SentenceType[]) => {
  return useMemo(() => {
    const result = sentences.map((s) => ({ ...s, task: TaskType.Collect }));

    return shuffle(result);
  }, [sentences]);
};
