import { LessonType, SentenceType, StatusType } from "App.types";
import React, { FC, useCallback, useEffect, useState } from "react";
import RecordButton from "Components/RecordButton";
import { Button, Flex, Popconfirm } from "antd";
import DialogTaskWriting from "./DialogTaskWriting";
import styles from "./DialogTaskTranslate.module.scss";
import cx from "classnames";
import { isMobile } from "react-device-detect";
import { BulbOutlined } from "@ant-design/icons";
import { useSpeechCheckTrans } from "Hooks/useSpeechCheckTrans";
import { SpeechResultType } from "Hooks/useDeepgramAlt";

const DialogTaskTranslate: FC<{
  onClickRecording: () => any;
  audioStatus: StatusType;
  sentence: SentenceType;
  onSuccess: Function;
  storyId?: number;
  speechResults: SpeechResultType[] | undefined;
  isSpeaking: boolean;
  lesson: LessonType;
  isWriting: boolean;
}> = ({
  speechResults,
  onClickRecording,
  sentence: { isLeft, translate },
  sentence,
  onSuccess,
  audioStatus,
  isSpeaking,
  lesson,
  isWriting,
}) => {
  const [taskStatus, setTaskStatus] = useState<StatusType>(StatusType.Empty);

  const { completedTagIdx, setCompletedTagIdx, activeTags, reset } = useSpeechCheckTrans({
    enabled: taskStatus !== StatusType.Completed,
    sentence,
    speechResults,
  });

  useEffect(() => {
    if (taskStatus !== StatusType.Completed && activeTags.length && completedTagIdx >= 0 && completedTagIdx + 1 >= activeTags.length - 1) {
      setTaskStatus(StatusType.Completed);
      onSuccess(sentence.id);
    }
  }, [activeTags.length, completedTagIdx, onSuccess, sentence.id, taskStatus]);

  const isCompleted = taskStatus === StatusType.Completed;

  const onHint = useCallback(() => {
    setCompletedTagIdx((prev) => prev + 1);
  }, [setCompletedTagIdx]);

  return (
    <div className={styles.dialogTaskTranslate}>
      <Flex gap={5} style={{ flexDirection: isLeft ? "row-reverse" : undefined }}>
        {!isCompleted && !isWriting && (
          <Popconfirm title={"Дать подсказку?"} onConfirm={onHint}>
            <Button
              // style={{ bottom: isMobile ? 4 : 0 }}
              size={isMobile ? "middle" : undefined}
              type={"text"}
              icon={<BulbOutlined />}
              className={cx(styles.bulb, { [styles.bulb__right]: !isLeft })}
            />
          </Popconfirm>
        )}
        {!isCompleted && !isWriting && (
          <RecordButton
            onClick={() => {
              reset();
              onClickRecording();
            }}
            isRecording={audioStatus === StatusType.IsRecording}
            loading={audioStatus === StatusType.Loading}
            isSpeaking={isSpeaking}
          />
        )}

        {isWriting ? (
          <DialogTaskWriting sentence={sentence} lesson={lesson} onTaskComplete={onSuccess} />
        ) : (
          <div className={cx(styles.message, { [styles.message__left]: isLeft })}>
            <div className={styles.text}>
              {activeTags.map((tag, idx) => (
                <span
                  className={cx(styles.tag, {
                    [styles.tag__hint]: tag.isHint && audioStatus !== StatusType.IsRecording,
                    [styles.tag__active]: !isCompleted,
                    [styles.tag__completed]: idx <= completedTagIdx || isCompleted,
                    [styles.tag__merged]: tag.merged,
                  })}
                  key={`${idx}${tag.word}`}
                >
                  {tag.word}
                </span>
              ))}
            </div>

            {!isCompleted && <div className={styles.translate}>{translate}</div>}
          </div>
        )}
      </Flex>
    </div>
  );
};

export default React.memo(DialogTaskTranslate);
