import { useMemo } from "react";
import { flatten, uniqBy } from "lodash";
import { DictionaryType, PhraseType, SentenceType, TagType } from "App.types";
import { ignoreTags, POS_TAGS } from "App.constants";
import API from "Api";
import { useQueries } from "@tanstack/react-query";

const useDictionary = (
  sentences?: SentenceType[] | { tags: TagType[]; phrases: { id: number; text: string; positionsIdx: number[] }[] },
  enabled: boolean = true,
): [DictionaryType & TagType & PhraseType] => {
  const words = useMemo(() => {
    if (!sentences) return [];

    const ar = (Array.isArray(sentences) ? sentences : [sentences]).reduce<any[]>(
      (acc, sent) => [
        ...acc,
        ...sent?.tags?.map((tag, idx) => (sent.phrases.find((ph) => ph.positionsIdx.includes(idx)) ? { word: "", pos: "" } : tag)),
      ],
      [],
    );

    const filteredAr = ar.filter((el) => el.word !== "UNAME" && !ignoreTags.includes(el.word) && !/^\d+$/.test(el.word));

    return uniqBy(
      filteredAr.map((el) => ({
        ...el,
        lemma: ["was", "were", "is", "are", "am"].includes(el.word) ? el.word : el.lemma,
        pos: POS_TAGS[el.pos],
        tag: el.pos,
      })),
      (tag) => `${tag.lemma}${tag.pos}`,
    );
  }, [sentences]);

  const phrases = useMemo(
    () =>
      sentences
        ? uniqBy(
            flatten(
              (Array.isArray(sentences) ? sentences : [sentences]).map(
                (sent) =>
                  sent?.phrases.map((ph) => ({
                    ...ph,
                    lemma: ph.text,
                    isPast: ph.positionsIdx.some((pos) => ["VBD", "VBN"].includes(sent.tags[pos]?.pos || "")),
                    text: ph.positionsIdx.map((pos) => sent.tags[pos]?.word).join(" "),
                  })),
              ),
            ),
            (ph) => ph?.text,
          )
        : [],
    [sentences],
  );

  const wordTranslates = useQueries({
    queries: words.map((w) => ({
      staleTime: Infinity,
      queryKey: ["word", w.lemma, w.pos],
      queryFn: () => API.dictionary.findOne(w.lemma, w.pos || w.tag?.toLowerCase()),
      select: (data: DictionaryType) => ({ ...w, ...data, text: w.word, word: data.word || w.word }),
      enabled,
    })),
  });

  const phraseTranslates = useQueries({
    queries: phrases.map((ph) => ({
      queryKey: ["word", ph?.lemma],
      queryFn: () => API.dictionary.findOne(ph?.lemma),
      staleTime: Infinity,
      enabled,
      select: (data: DictionaryType) => ({ ...ph, ...data }),
    })),
  });

  // @ts-ignore
  return useMemo(
    () => [...phraseTranslates.map((el) => el.data).filter((el) => el), ...wordTranslates.map((tr) => tr.data).filter((data) => data)],
    [phraseTranslates, wordTranslates],
  );
};

// @ts-ignore
export default useDictionary;
