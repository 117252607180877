import { createClient, DeepgramClient, LiveClient } from "@deepgram/sdk";
import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import API from "Api";
import { StatusType, TagType } from "App.types";
import { UserContext } from "App";
import Cookies from "js-cookie";
import { uniq } from "lodash";

type Props = {
  tags: Partial<TagType>[] | undefined;
  text: string | undefined;
  setStatus: Dispatch<SetStateAction<StatusType>>;
  interim_results?: boolean;
  smart_format?: boolean;
  model?: "enhanced" | "nova-2" | "nova" | string | undefined;
  easy?: boolean;
};

type WordType = { confidence: number; word: string; punctuated_word: string; start: number; end: number };

export type SpeechResultType = { transcript: string; confidence: number; words: WordType[] };

const useDeepgramAlt = ({
  setStatus,
  text,
  smart_format = false,
  interim_results = true,
  model = "nova-voicemail", // easy,
}: Props): {
  start: () => any;
  stop: () => any;
  reset: Function;
  results: SpeechResultType[];
  isSpeaking: boolean;
  transcript: string;
} => {
  const [results, setResults] = useState<SpeechResultType[]>([]);
  // const [wordResults, setWordResults] = useState<{ [start: string]: WordType & { isFinal: boolean } }>({});
  const [token, setToken] = useState<any>(() => {
    const lastToken = Cookies.get("dg-token");
    if (lastToken) {
      return JSON.parse(lastToken);
    }
  });

  const [isSpeaking, setIsSpeaking] = useState(false);

  const transcript = useMemo(() => results[0]?.transcript ?? "", [results]);

  const userMedia = useRef<MediaStream>();
  const socket = useRef<LiveClient>();
  const microphone = useRef<MediaRecorder>();

  const client = useRef<DeepgramClient>();
  const hasError = useRef(false);

  const lastItem = useRef<{ last: number; isFinal: boolean | undefined }>({ last: -1, isFinal: true });

  const user = useContext(UserContext);

  const speechActiveTimeout = useRef<any>(null);
  const resultsTimeoutId = useRef<any>(null);

  //const defaultKeywords = useMemo(() => (text && text.split(" ").length < 4 ? text.split(" ") : []), [text]);

  // const keywords = useMemo(
  //   () =>
  //     easy
  //       ? uniq([...tags.map((t) => `${t.word}`)].filter((w) => w?.replace(/[\W_]+/g, "")).map((w) => `${w}:2`))
  //       : uniq(tags.filter((t) => t.isHint || t.pos?.includes("NNP")).map((t) => t.word)),
  //   [tags, easy],
  // );

  const keywords = useMemo(
    () =>
      uniq(
        text
          ?.split(" ")
          //.map((w) => w?.replace(/[\W_]+/g, ""))
          .filter((w) => w?.replace(/[\W_]+/g, ""))
          .map((w) => `${w}:2`),
      ),
    [text],
  );

  useEffect(() => {
    if (socket.current?.isConnected() && text) {
      console.log("RECONNECT");
      microphone.current?.stop();

      socket.current?.disconnect();
      socket.current?.reconnect({
        model,
        language: "en",
        smart_format,
        interim_results,
        alternatives: model === "nova-2" ? 1 : 5,
        vad_events: true,
        tag: [`${user?.id}`],
        //@ts-ignore
        keywords,
      });

      clearTimeout(resultsTimeoutId.current);
    }
  }, [interim_results, keywords, model, setStatus, smart_format, user?.id, text]);

  const stop = useCallback(() => {
    console.log("STOP");
    socket.current?.requestClose();
    socket.current?.disconnect();

    setStatus((prevState) => (prevState !== StatusType.Completed ? StatusType.Empty : prevState));

    microphone.current?.stop();
    microphone.current = undefined;
    if (userMedia.current) userMedia?.current.getTracks().forEach((track) => track.stop());
  }, [setStatus]);

  const start = useCallback(
    async (key = "") => {
      lastItem.current = { last: -1, isFinal: true };

      setStatus(StatusType.Loading);
      setResults([]);

      try {
        navigator.mediaDevices.enumerateDevices().then(function (mediaDevices) {
          console.log("mediaDevices: ", mediaDevices);
        });

        userMedia.current = await navigator.mediaDevices.getUserMedia({
          audio: {
            noiseSuppression: true,
            echoCancellation: true,
            deviceId: "default",
          },
        });
        microphone.current = new MediaRecorder(userMedia.current);

        if (!userMedia) return console.error("no UserMedia available");
      } catch (error) {
        setStatus(StatusType.Empty);
      }

      if (key) {
        client.current = await createClient(key);
      } else {
        const newToken = token || (await API.user.getDGToken());
        setToken(newToken);
        Cookies.set("dg-token", JSON.stringify(newToken), { expires: new Date(newToken.expiration_date) });
        client.current = await createClient(newToken.key);
      }

      if (!socket.current || socket.current?.getReadyState() !== 1) {
        socket.current = client.current.listen.live({
          model,
          language: "en",
          smart_format,
          interim_results,
          alternatives: model === "nova-2" ? 1 : 5,
          vad_events: true,
          tag: [`${user?.id}`],
          // search: tags?.map((t) => t.word).join(" "),
          //@ts-ignore
          keywords,
        });

        console.log("client: connected to websocket");

        socket.current.on("SpeechStarted", () => {
          setIsSpeaking(true);
          clearTimeout(speechActiveTimeout.current);
          speechActiveTimeout.current = setTimeout(() => setIsSpeaking(false), 1000);
        });

        socket.current?.on("error", async (e) => {
          console.error("ERROR", e);
          microphone.current?.stop();

          setToken(null);
          setStatus((prevState) => (prevState !== StatusType.Completed ? StatusType.Empty : prevState));

          if (!hasError.current) {
            hasError.current = true;
            const newToken = await API.user.getDGToken();
            setToken(newToken);
            start(newToken.key);
          }
        });

        socket.current.on("open", async () => {
          console.log("OPEN");
          clearTimeout(resultsTimeoutId.current);
          //@ts-ignore
          microphone.current.ondataavailable = (e) => {
            // console.log("voice: sent data to websocket");
            socket.current?.send(e.data);
          };

          microphone.current?.start(500);

          setStatus(StatusType.IsRecording);
          hasError.current = false;
          resultsTimeoutId.current = setTimeout(() => stop(), 8000);
        });

        socket.current?.on("Results", (data) => {
          const alternatives = data.channel.alternatives.filter((a: any) => a.transcript?.trim() && a.confidence > 0.5);

          alternatives?.length && console.log(alternatives.map((a: any) => `${a.transcript}:${a.confidence}`));

          if (alternatives.length) {
            clearTimeout(resultsTimeoutId.current);
            resultsTimeoutId.current = setTimeout(() => stop(), 8000);
            setResults(alternatives);
          }
        });

        socket.current?.on("warning", (e) => console.warn(e));

        // socket.current?.on("Metadata", (e) => console.log(e));

        socket.current?.on("close", () => {
          console.log("SPEECH CLOSED");
          clearTimeout(resultsTimeoutId.current);
          setResults([]);
          microphone.current?.stop();
          if (userMedia.current) userMedia?.current.getTracks().forEach((track) => track.stop());
          setStatus((prevState) => (prevState !== StatusType.Completed ? StatusType.Empty : prevState));
        });
      }
    },
    [keywords, setStatus, token, model, user?.id, smart_format, interim_results, stop],
  );

  const reset = useCallback(() => {
    socket.current?.finalize();

    clearTimeout(resultsTimeoutId.current);
    resultsTimeoutId.current = setTimeout(() => stop(), 8000);
    setResults(() => {
      // lastItem.current = { last: last(prev)?.start ?? -1, isFinal: last(prev)?.isFinal };
      return [];
    });
  }, [stop]);

  return { start, stop, results, reset, isSpeaking, transcript };
};

export default useDeepgramAlt;
