import { SentenceType, StatusType, TagType, TaskType } from "App.types";
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { keys, shuffle } from "lodash";
import { Flex } from "antd";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import cx from "classnames";
import { arrayMove } from "../SentenceTask.helpers";
import styles from "./VideoDragDrop.module.scss";
import { successMessage } from "App.constants";
import { SentenceTaskProps } from "../SentenceTask.type";
import TaskPanel from "Components/TaskPanel";
import { isMobile } from "react-device-detect";
import VideoPlayerSingle from "../VideoPlayerSingle";
import { NotifyContext } from "App";

const VideoDragDrop: FC<SentenceTaskProps> = ({
  lesson,
  sentence: { linkedStory, id },
  onTaskComplete,
  onNext,
  showGrammar,
  showSuccessMessage,
}) => {
  const [dropTags, setDropTags] = useState<{ [key: string]: { text: string; draggableId: string } | undefined }>({});
  const [tagsToSelect, setTagsToSelect] = useState<SentenceType[]>([]);
  const [wrongTags, setWrongTags] = useState<SentenceType[]>([]);
  const [selectedTag, setSelectedTag] = useState<SentenceType>();
  const [activeVideo, setActiveVideo] = useState<string>();
  const [status, setStatus] = useState<StatusType>();

  const { sentences = [] } = linkedStory || {};

  useEffect(() => {
    setTagsToSelect([...sentences]);
  }, [sentences]);

  const shuffledSentences = useMemo(() => shuffle(sentences), [sentences]);

  const isCompleted = status === StatusType.Completed;

  const tagsAsObjects: { [key: string]: TagType } = useMemo(
    () => sentences.reduce((acc, t) => ({ ...acc, [`${t.id}`]: t }), {}),
    [sentences],
  );

  const notifyApi = useContext(NotifyContext);

  const onCheck = useCallback(() => {
    let hasErrors = false;
    sentences.forEach((s) => {
      if (s.text && dropTags[`${s.id}`]?.draggableId !== `${s.id}`) {
        hasErrors = true;
        setWrongTags((prev) => [...prev, s]);
      }
    });

    notifyApi.destroy();

    if (!hasErrors) {
      setStatus(StatusType.Completed);
      onTaskComplete();
      showSuccessMessage && notifyApi.success(successMessage);
    } else {
      if (tagsToSelect.length) {
        notifyApi.error({ message: "Заполни все пропуски!" });
      } else {
        notifyApi.error({ message: "Есть ошибки!" });
      }
    }
  }, [dropTags, notifyApi, onTaskComplete, sentences, tagsToSelect.length, showSuccessMessage]);

  const onDragEnd = (dropResult: DropResult) => {
    const { source, destination, draggableId } = dropResult;
    const tag = sentences.find((t) => `${t.id}` === draggableId);

    if (source.droppableId === destination?.droppableId && source.index === destination.index) return;

    setWrongTags((prev) => prev.filter((s) => s.id !== +(destination?.droppableId || 0)));

    if (destination?.droppableId === "answers" && source.droppableId === "answers") {
      setTagsToSelect((prev) => arrayMove(prev, source.index, destination.index));

      return;
    }

    if (destination?.droppableId && tag) {
      if (source.droppableId === "answers") {
        setTagsToSelect((prev) => {
          prev.splice(source.index, 1);
          return prev;
        });

        setDropTags((prev) => ({
          ...prev,
          [destination.droppableId]: { text: tag.text, draggableId },
        }));

        if (dropTags[destination.droppableId]) {
          const sent = sentences.find((s) => `${s.id}` === dropTags[destination.droppableId]?.draggableId);
          sent && setTagsToSelect((prev) => [...prev, { ...sent }]);
        }

        return;
      }

      if (destination.droppableId === "answers") {
        setTagsToSelect((prev) => {
          prev.splice(destination.index, 0, tag);
          return prev;
        });

        if (source.droppableId !== "answers") {
          setDropTags((prev) => ({ ...prev, [source.droppableId]: undefined }));
        }

        return;
      }

      setDropTags((prev) => ({ ...prev, [source.droppableId]: undefined, [destination.droppableId]: { text: tag.text, draggableId } }));
      if (dropTags[destination.droppableId]) {
        const sent = sentences.find((s) => `${s.id}` === dropTags[destination.droppableId]?.draggableId);
        sent && setTagsToSelect((prev) => [...prev, { ...sent }]);
      }
    }
  };

  useEffect(() => {
    const hasWrong = keys(dropTags).some((key) => tagsAsObjects[key]?.word !== dropTags[key]?.text);

    if (!hasWrong && tagsToSelect.length === 0) {
      //  onTaskComplete();
    }
  }, [dropTags, tagsAsObjects, sentences.length, onTaskComplete, tagsToSelect.length]);

  const onDropClick = useCallback(
    (targetId: string) => {
      if (!selectedTag) return;

      setWrongTags((prev) => prev.filter((s) => `${s.id}` !== targetId));

      setTagsToSelect((prev) => {
        const filtered = prev.filter((t) => t.id !== selectedTag.id);

        if (dropTags[targetId]) {
          const sent = sentences.find((s) => `${s.id}` === dropTags[targetId]?.draggableId);
          if (sent) return [...filtered, sent];
        }
        return filtered;
      });

      const prevDropZone = keys(dropTags).find((k) => dropTags[k]?.draggableId === `${selectedTag.id}`);

      setDropTags((prev) => ({
        ...prev,
        [prevDropZone || ""]: undefined,
        [targetId]: { text: selectedTag.text, draggableId: `${selectedTag.id}` },
      }));

      setSelectedTag(undefined);
    },

    [selectedTag, dropTags, sentences],
  );

  return (
    <div className={cx(styles.VideoDragDrop, { [styles.mobile]: isMobile })}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={"answers"} direction={"horizontal"}>
          {(provided, snapshot) => (
            <Flex
              wrap={"wrap"}
              gap={10}
              className={styles.texts}
              {...provided.droppableProps}
              ref={provided.innerRef}
              // isDraggingOver={snapshot.isDraggingOver}
            >
              {tagsToSelect.map((t, index) => (
                <Draggable key={t.id} index={index} draggableId={`${t.id}`}>
                  {(draggableProvided, snapshot) => (
                    <span
                      className={cx(styles.tag, {
                        [styles.tag__selected]: t === selectedTag,
                        [styles.tag__nonSelected]: selectedTag && t !== selectedTag,
                      })}
                      ref={draggableProvided.innerRef}
                      onClick={() => setSelectedTag((prev) => (prev === t ? undefined : t))}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                    >
                      {t.text}
                    </span>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
              <span />
            </Flex>
          )}
        </Droppable>

        <div className={styles.cards}>
          {shuffledSentences.map((s) => (
            <Droppable key={`${s.id}`} droppableId={`${s.id}`} direction={"horizontal"} isDropDisabled={isCompleted}>
              {(provided, snapshot) => (
                <div
                  className={cx(styles.droppableCard, {
                    [styles.droppableCard__wrong]: wrongTags.includes(s),
                    [styles.droppableCard__hover]: selectedTag,
                    [styles.droppableCard__hovered]: snapshot.isDraggingOver,
                    [styles.droppableCard__filled]: dropTags[`${s.id}`] && !snapshot.draggingFromThisWith,
                  })}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  onClick={() => onDropClick(`${s.id}`)}
                >
                  <div className={cx(styles.dropZone, { [styles.dropZone__filled]: dropTags[`${s.id}`] })}>
                    {dropTags[`${s.id}`] ? (
                      <Draggable
                        // isDragDisabled={dropTags[`${s.id}`]?.text === s.text}
                        index={s.id}
                        // @ts-ignore
                        key={dropTags[`${s.id}`].draggableId}
                        // @ts-ignore
                        draggableId={dropTags[`${s.id}`].draggableId}
                        //isDragDisabled={isCompleted}
                      >
                        {(draggableProvided, snapshot) => (
                          <span
                            ref={draggableProvided.innerRef}
                            onClick={(e) => {
                              e.stopPropagation();
                              const sent = sentences.find((sent) => `${sent.id}` === dropTags[`${s.id}`]?.draggableId);
                              setSelectedTag((prev) => (prev === sent ? undefined : sent));
                            }}
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                          >
                            <span
                              className={cx(styles.tag, {
                                [styles.tag__selected]: dropTags[`${s.id}`]?.draggableId === `${selectedTag?.id}`,
                                [styles.tag__wrong]: !snapshot.isDragging && dropTags[`${s.id}`]?.text !== s.text,
                              })}
                            >
                              {dropTags[`${s.id}`]?.text}
                            </span>
                          </span>
                        )}
                      </Draggable>
                    ) : undefined}
                  </div>

                  <VideoPlayerSingle
                    onClick={() => setActiveVideo(`${s.id}`)}
                    isPlaying={activeVideo === `${s.id}`}
                    onFinish={() => {}}
                    // isPlaying={activeVideo === `${s.id}`}
                    //url={`${STATIC_URL}/media/${s.medias[0]?.url}`}
                    url={s.medias[0]?.url}
                    isHovered={snapshot.isDraggingOver}
                  />
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ))}
        </div>
      </DragDropContext>

      <TaskPanel
        sentId={id}
        lessonId={lesson.id}
        task={TaskType.VideoDragDrop}
        storyId={linkedStory?.id}
        onCheck={onCheck}
        isCompleted={status === StatusType.Completed}
        onNext={onNext}
        showGrammar={showGrammar}
      ></TaskPanel>
    </div>
  );
};

export default VideoDragDrop;
