import useAlienColor from "./useAlienColor";
import useAlienWink from "./useAlienWink";
import useAlienJump from "./useAlienJump";
import { useCallback, useRef } from "react";
import { delay, random } from "lodash";

const useAlienIdle = () => {
  const { start: startColor, stop: stopColor } = useAlienColor();
  const { play: startWink, stop: stopWink } = useAlienWink();
  const { jump } = useAlienJump();

  const timerRef = useRef<any>();

  const startJumpingTimeout = useCallback(() => {
    stopWink();
    delay(jump, 1000);
    delay(startWink, 2000);
    timerRef.current = setTimeout(startJumpingTimeout, random(10, 50) * 1000);
  }, [jump, startWink, stopWink]);

  const startIdle = useCallback(() => {
    startColor();
    startWink();
    startJumpingTimeout();
  }, [startColor, startWink, startJumpingTimeout]);

  const stopIdle = useCallback(() => {
    stopColor();
    stopWink();
    clearTimeout(timerRef.current);
  }, [stopColor, stopWink]);

  return { startIdle, stopIdle };
};

export default useAlienIdle;
