import React, { FC, PropsWithChildren, useEffect, useMemo, useState } from "react";
import cx from "classnames";
import styles from "./VideoPlayer.module.scss";
import { MediaType } from "App.types";
import VideoPlayerItem from "./VideoPlayerItem";
import { useSuspenseQueries } from "@tanstack/react-query";
import axios from "axios";
import { STATIC_URL } from "App.constants";
import { encodeS3URI } from "App.helpers";
import { isMobile } from "react-device-detect";

type Props = {
  medias: Partial<MediaType>[];
  onFinish?: () => any;
  autoPlay?: boolean;
  showCaptions: boolean;
  largeCaptionSize?: boolean;
  className?: string;
  withFullscreen?: boolean;
};

const VideoPlayer: FC<Props & PropsWithChildren> = ({
  withFullscreen,
  className = "",
  largeCaptionSize,
  showCaptions,
  onFinish,
  medias,
  autoPlay = false,
}) => {
  const [activeIdx, setActiveIdx] = useState<number>(0);
  const [isPlaying, setPlaying] = useState(autoPlay);
  const [finished, setFinished] = useState(false);

  useEffect(() => {
    autoPlay && setPlaying(true);
  }, [autoPlay]);

  const queries = useSuspenseQueries({
    queries: medias.slice(1, medias.length).map((m) => ({
      staleTime: Infinity,
      queryKey: ["media", m.url],
      queryFn: () =>
        axios.get(`${STATIC_URL}/media/${encodeS3URI(m.url)}`, { headers: { "Cache-Control": "no-cache" }, responseType: "blob" }),
    })),
    combine: (data) => data.map((resp) => URL.createObjectURL(resp.data.data)),
  });

  const urls = useMemo(() => [`${STATIC_URL}/media/${encodeS3URI(medias[0].url)}`, ...queries], [medias, queries]);

  const activeClip = medias[activeIdx];

  return (
    <div
      className={cx(styles.videoPlayer, className, {
        [styles.largeCaptions]: largeCaptionSize,
        [styles.videoPlayer__withCaptions]: showCaptions,
        [styles.videoPlayer__mobile]: isMobile,
      })}
      onClick={() => {
        if (finished) {
          setFinished(false);
        }
        setPlaying((prev) => !prev);
      }}
    >
      <VideoPlayerItem
        allVideosPlayed={finished}
        url={urls[activeIdx]}
        withFullscreen={withFullscreen}
        isPlaying={isPlaying}
        clip={activeClip}
        text={showCaptions ? activeClip.title : ""}
        onFinish={() => {
          setActiveIdx((prev = 0) => {
            if (prev === medias.length - 1) {
              setPlaying(false);
              onFinish?.();
              setFinished(true);
              return 0;
            }
            return prev + 1;
          });
        }}
      />
    </div>
  );
};

export default VideoPlayer;
