import { useCallback, useEffect, useRef, useState } from "react";
import { uniqueId } from "lodash";

const downs = [
  "M415,289.2c0,95.1-112.1,222.8-194.5,222.8S26,384.5,26,289.2,113.1,117,220.5,117s194.5,77.1,194.5,172.2Z",
  "M217.7,117c-106,1.2-191.7,78-191.7,172.2s111.1,221.7,193.5,222.6c-8.6-91.5-52.8-151-75.7-267.3-14.1-69.8,28.9-107.5,73.9-127.4Z",
  "M335.4,352.3c28.5-25.2,37.2-58.2,19.9-73.6-17.3-15.4-54.6-7.5-83.1,17.7-28.5,25.2-37.3,58.2-19.9,73.7,17.4,15.4,54.6,7.5,83.1-17.7h0Z",
  "M103.8,352.3c28.5,25.2,65.6,33.1,83.1,17.7,17.4-15.4,8.6-48.4-19.9-73.6s-65.6-33.1-83.1-17.7c-17.4,15.4-8.6,48.4,19.9,73.6Z",
  "M220.5,427.8c-8.8,0-17.4-2.5-24.7-7.5-3-2.1-3.5-5.8-1.1-8.3,2.3-2.7,6.5-3.1,9.5-1.1,9.7,6.6,23.2,6.6,32.9,0,3-2.1,7.2-1.5,9.5,1.1,2.3,2.7,1.8,6.5-1.1,8.3-7.5,5-16.3,7.5-24.9,7.5h0Z",
  "M83.9,278.7c-17.4,15.4-8.6,48.4,19.9,73.6,16.7,14.7,36.1,23.5,53.1,25.2-1.8-.4-30.1-6.2-37.9-44.7-8.1-39.3,47.9-36.4,47.9-36.4-28.3-25.1-65.3-33-83-17.6h0Z",
  "M355,278.7c17.4,15.4,8.6,48.4-19.9,73.6-16.7,14.7-36.1,23.5-53.1,25.2,1.8-.4,30.1-6.2,37.9-44.7,8.1-39.3-47.9-36.4-47.9-36.4,28.3-25.1,65.6-33,83-17.6h0Z",
];

const useAlienJump = ({ duration } = { duration: 700 }) => {
  const [active, setActive] = useState(false);
  const [trigger, setTrigger] = useState<string>();

  const play = useCallback(() => setActive(true), []);
  const stop = useCallback(() => setActive(false), []);
  const jump = useCallback(() => setTrigger(uniqueId("jump")), []);

  const intervalRef = useRef<any>();

  useEffect(() => {
    if (active) {
      setTrigger(uniqueId("jump"));
      intervalRef.current = setInterval(() => setTrigger(uniqueId("jump")), duration);
    } else {
      setTrigger(undefined);
      clearInterval(intervalRef.current);
    }
  }, [active, duration]);

  useEffect(() => {
    const id = "rightHead";

    const move1 = "M404.6,190.8c0,105.4-105.4,246.9-183,246.9S38.6,296.4,38.6,190.8,120.5,0,221.6,0s183,85.5,183,190.8Z";
    const parent = document.getElementById(id);

    const originPath = parent?.getAttribute("d");

    if (trigger) {
      const prev = document.getElementById(`${id}-anim`);
      if (prev) parent?.removeChild(prev);

      const anim = document.createElementNS("http://www.w3.org/2000/svg", "animate");
      anim.setAttribute("id", `${id}-anim`);

      //anim.setAttribute("repeatCount", "indefinite");
      anim.setAttribute("attributeName", "d");
      anim.setAttribute("dur", `${duration}ms`);
      // anim.setAttribute("fill", "freeze");
      anim.setAttribute("values", [originPath, move1, downs[0], originPath].join(";"));

      parent?.appendChild(anim);
      anim.beginElement();
    }
  }, [trigger, duration]);

  useEffect(() => {
    const id = "leftHead";

    const move1 =
      "M219,0C119.2,1.4,38.6,86.4,38.6,190.8s104.5,245.7,182.1,246.6c-8.1-101.4-49.7-167.3-71.2-296.2C136.2,63.9,176.7,22.1,219,0Z";
    const parent = document.getElementById(id);

    const originPath = parent?.getAttribute("d");

    if (trigger) {
      const prev = document.getElementById(`${id}-anim`);
      if (prev) parent?.removeChild(prev);
      const anim = document.createElementNS("http://www.w3.org/2000/svg", "animate");
      anim.setAttribute("id", `${id}-anim`);

      anim.setAttribute("attributeName", "d");
      anim.setAttribute("dur", `${duration / 1000}s`);
      anim.setAttribute("values", [originPath, move1, downs[1], originPath].join(";"));

      parent?.appendChild(anim);
      anim.beginElement();
    }
  }, [trigger, duration]);

  useEffect(() => {
    const id = "eRight";

    const move1 =
      "M329.7,260.7c26.8-27.9,35-64.5,18.8-81.5-16.3-17-51.4-8.3-78.2,19.6-26.8,27.9-35.1,64.5-18.8,81.6,16.4,17,51.4,8.3,78.2-19.7h0Z";
    const parent = document.getElementById(id);

    const originPath = parent?.getAttribute("d");

    if (trigger) {
      const prev = document.getElementById(`${id}-anim`);
      if (prev) parent?.removeChild(prev);
      const anim = document.createElementNS("http://www.w3.org/2000/svg", "animate");
      anim.setAttribute("id", `${id}-anim`);

      anim.setAttribute("attributeName", "d");
      anim.setAttribute("dur", `${duration / 1000}s`);
      anim.setAttribute("values", [originPath, move1, downs[2], originPath].join(";"));

      parent?.appendChild(anim);
      anim.beginElement();
    }
  }, [trigger, duration]);

  useEffect(() => {
    const id = "eLeft";

    const move1 =
      "M111.8,260.7c26.8,27.9,61.7,36.7,78.2,19.6,16.4-17.1,8.1-53.7-18.8-81.5s-61.7-36.7-78.2-19.6c-16.4,17-8.1,53.7,18.8,81.5Z";
    const parent = document.getElementById(id);

    const originPath = parent?.getAttribute("d");

    if (trigger) {
      const prev = document.getElementById(`${id}-anim`);
      if (prev) parent?.removeChild(prev);
      const anim = document.createElementNS("http://www.w3.org/2000/svg", "animate");
      anim.setAttribute("id", `${id}-anim`);

      anim.setAttribute("attributeName", "d");
      anim.setAttribute("dur", `${duration / 1000}s`);
      anim.setAttribute("values", [originPath, move1, downs[3], originPath].join(";"));

      parent?.appendChild(anim);
      anim.beginElement();
    }
  }, [trigger, duration]);

  useEffect(() => {
    const id = "alien_mouth";

    const move1 =
      "M221.6,344.3c-8.3,0-16.4-2.7-23.3-8.3-2.8-2.3-3.3-6.4-1.1-9.2,2.1-3,6.1-3.4,9-1.2,9.1,7.4,21.8,7.4,30.9,0,2.8-2.3,6.7-1.6,9,1.2,2.1,3,1.7,7.2-1.1,9.2-7.1,5.6-15.4,8.3-23.4,8.3h0Z";
    const parent = document.getElementById(id);

    const originPath = parent?.getAttribute("d");

    if (trigger) {
      const prev = document.getElementById(`${id}-anim`);
      if (prev) parent?.removeChild(prev);
      const anim = document.createElementNS("http://www.w3.org/2000/svg", "animate");
      anim.setAttribute("id", `${id}-anim`);

      anim.setAttribute("attributeName", "d");
      anim.setAttribute("dur", `${duration / 1000}s`);
      anim.setAttribute("values", [originPath, move1, downs[4], originPath].join(";"));

      parent?.appendChild(anim);
      anim.beginElement();
    }
  }, [trigger, duration]);

  useEffect(() => {
    const id = "pLeft";

    const move1 =
      "M93,179.2c-16.4,17-8.1,53.7,18.8,81.5,15.7,16.3,34,26.1,49.9,27.9-1.7-.4-28.3-6.9-35.7-49.6-7.6-43.6,45.1-40.3,45.1-40.3-26.6-27.8-61.4-36.6-78.1-19.5h0Z";
    const parent = document.getElementById(id);

    const originPath = parent?.getAttribute("d");

    if (trigger) {
      const prev = document.getElementById(`${id}-anim`);
      if (prev) parent?.removeChild(prev);
      const anim = document.createElementNS("http://www.w3.org/2000/svg", "animate");
      anim.setAttribute("id", `${id}-anim`);

      anim.setAttribute("attributeName", "d");
      anim.setAttribute("dur", `${duration / 1000}s`);
      anim.setAttribute("values", [originPath, move1, downs[5], originPath].join(";"));

      parent?.appendChild(anim);
      anim.beginElement();
    }
  }, [trigger, duration]);

  useEffect(() => {
    const id = "pRight";

    const move1 =
      "M348.2,179.2c16.4,17,8.1,53.7-18.8,81.5-15.7,16.3-34,26.1-49.9,27.9,1.7-.4,28.3-6.9,35.7-49.6,7.6-43.6-45.1-40.3-45.1-40.3,26.6-27.8,61.7-36.6,78.1-19.5h0Z";
    const parent = document.getElementById(id);

    const originPath = parent?.getAttribute("d");

    if (trigger) {
      const prev = document.getElementById(`${id}-anim`);
      if (prev) parent?.removeChild(prev);
      const anim = document.createElementNS("http://www.w3.org/2000/svg", "animate");
      anim.setAttribute("id", `${id}-anim`);

      anim.setAttribute("attributeName", "d");
      anim.setAttribute("dur", `${duration / 1000}s`);
      anim.setAttribute("values", [originPath, move1, downs[6], originPath].join(";"));

      parent?.appendChild(anim);
      anim.beginElement();
    }
  }, [trigger, duration]);

  return { play, stop, jump };
};

export default useAlienJump;
