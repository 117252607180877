import { Badge, Button, Collapse, Flex, Popover, Progress, Rate, Tag, Timeline, Tooltip } from "antd";
import { GrammarType, LessonType, ProgressType, SentenceType } from "App.types";
import React, { Dispatch, FC, SetStateAction, useContext, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Levels, STATIC_URL } from "App.constants";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import cx from "classnames";
import { UserContext } from "App";
import { TimelineItemProps } from "antd/es/timeline/TimelineItem";
import { getStepColor } from "./Lessons.conts";
import styles from "./LessonsModule.module.scss";
import { BookTwoTone, CheckCircleOutlined, CrownTwoTone, HeartOutlined, LockFilled } from "@ant-design/icons";
import { findLast } from "lodash";
import { encodeS3URI, replaceTemplates } from "App.helpers";
import { useSuspenseQuery } from "@tanstack/react-query";
import API from "Api";

const LessonsModule: FC<{
  progress: { [p: number]: ProgressType };
  intros?: { [story: number]: SentenceType };
  lessons: LessonType[];
  isCompleted: boolean;
  lesson: LessonType;
  isGrammar?: boolean;
  openGrammarPanel?: Dispatch<SetStateAction<GrammarType | undefined>>;
}> = ({ intros, openGrammarPanel, isGrammar, isCompleted, progress, lessons, lesson: { level = 0, id, description, isActive } }) => {
  const [scrollOnce, setScrollOnce] = useState<boolean>(false);
  const navigate = useNavigate();
  const user = useContext(UserContext);

  const { data: userProps } = useSuspenseQuery({
    queryKey: ["props", user?.id],
    queryFn: API.user.getProps,
    staleTime: Infinity,
  });

  const childLessons = useMemo(() => lessons.filter((l) => l.moduleId === id), [lessons, id]);

  const lessonsOnly = useMemo(() => lessons.filter((l) => l.type !== "module" && !l.optional), [lessons]);

  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (ref.current && !scrollOnce) {
      setScrollOnce(true);
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [scrollOnce]);

  const items: TimelineItemProps[] = childLessons.map((lesson, idx) => {
    const { story, isActive } = lesson;

    const { picture, title, type, translate, wordCount = 0 } = story || {};

    const stars = progress?.[lesson.id]?.stars;

    const isStarted = progress?.[lesson.id];

    const isCompleted = progress?.[lesson.id]?.status === "completed";

    const prevLesson = findLast(lessonsOnly, (l) => l.order < lesson.order);
    const isLocked =
      !user?.isAdmin && prevLesson && progress?.[prevLesson.id]?.status !== "completed" && (progress?.[prevLesson.id]?.tries ?? 0) < 2;

    const intro = intros?.[lesson.storyId];

    return {
      // dot: isLocked ? <LockFilled /> : isCompleted ? <CheckOutlined /> : undefined,
      dot: isLocked ? (
        <LockFilled />
      ) : isCompleted ? (
        <CheckCircleOutlined color={"green"} />
      ) : progress?.[lesson.id]?.percent ? (
        <Progress size={16} type="circle" percent={progress[lesson.id].percent} />
      ) : undefined,
      color: getStepColor(progress?.[lesson.id]?.percent),
      className: cx(styles.item, {
        [styles.item_nextCompleted]:
          lessons[idx + 1] && progress?.[lessons[idx + 1].id]?.status === "completed" && progress?.[lesson.id]?.status === "completed",
      }),
      children: (
        <Popover
          openClassName={styles.selected}
          color={"lightyellow"}
          trigger={"click"}
          content={
            intro &&
            !isStarted && (
              <div className={cx(styles.popover__content, { [styles.popover__content__mobile]: isMobile })}>
                <div
                  className={styles.popover__title}
                  dangerouslySetInnerHTML={{ __html: replaceTemplates(intro?.description, userProps) ?? "" }}
                />
                <Button
                  type={"primary"}
                  size={isMobile ? "middle" : undefined}
                  className={styles.btnStart}
                  onClick={
                    lesson.type === "grammar"
                      ? () => openGrammarPanel?.(lesson.grammar)
                      : () => navigate(`lessons/${type}/${lesson.id}`, { state: { scrollPosition: window.scrollY } })
                  }
                >
                  {intro?.translate ?? "Let's go!"}
                </Button>
                <div>
                  <Collapse
                    ghost
                    size={"small"}
                    items={
                      intro?.linkedStory
                        ? [
                            {
                              label: <div className={styles.popover__collapse}>Содержимое урока</div>,
                              children: (
                                <div>
                                  <ul>{intro?.linkedStory?.sentences.map((s) => <li key={s.id}>{s.text}</li>)}</ul>
                                  <Button
                                    onClick={() => navigate(`lessons/intro/${lesson.id}/${intro?.linkedStoryId}`)}
                                    size={isMobile ? "small" : "middle"}
                                    type={"link"}
                                  >
                                    skip lesson
                                  </Button>
                                </div>
                              ),
                            },
                          ]
                        : undefined
                    }
                  />
                </div>
              </div>
            )
          }
        >
          <div
            id={`${id}`}
            ref={isCompleted ? ref : undefined}
            className={cx(styles.lesson, { [styles.lesson_completed]: isCompleted, [styles.lesson_locked]: isLocked })}
            // style={{ backgroundImage: `url(${STATIC_URL}/lessons/${story.title?.toLowerCase()}.svg)` }}
            // style={{ backgroundImage: `url(${STATIC_URL}/stories/${story.title}/images/cover.svg)` }}
            onClick={
              intro && !isStarted
                ? undefined
                : lesson.type === "grammar"
                  ? () => openGrammarPanel?.(lesson.grammar)
                  : () => navigate(`lessons/${type}/${lesson.id}`, { state: { scrollPosition: window.scrollY } })
            }
          >
            <Flex justify={"center"} className={styles.coverWrapper}>
              {picture ? (
                <img
                  className={cx(styles.cover, { [styles.cover__jpg]: picture?.includes("jpg") })}
                  alt={""}
                  src={`${STATIC_URL}/stories/${encodeS3URI(title)}/${picture}`}
                />
              ) : (
                <img className={cx(styles.cover)} alt={""} src={`${STATIC_URL}/covers/${type}.svg`} />
              )}
            </Flex>

            {!isGrammar && !!wordCount && (
              <div className={styles.lesson__desc}>
                <CrownTwoTone />
                {` ${wordCount}`}
                {!isActive && <Tag color={"red"}>inactive</Tag>}
              </div>
            )}

            <div className={styles.lesson__title}>
              {lesson.grammar && <BookTwoTone />} {lesson.grammar?.title || title}
            </div>
            <div className={styles.lesson__desc}>{story?.description || lesson.description || translate}</div>
            {[1, 427].includes(user?.id ?? 0) && <div>{story?.author}</div>}

            {stars && (
              <Tooltip title={"Оценка выполнения задания"}>
                <Rate className={styles.stars} allowHalf disabled defaultValue={stars} character={<HeartOutlined />} />
              </Tooltip>
            )}
          </div>
        </Popover>
      ),
    };
  });

  return (
    <Badge.Ribbon
      rootClassName={cx(styles.lessonsModule, { [styles.mobile]: isMobile, [styles.lessonsModule__completed]: isCompleted })}
      className={cx({ [styles.ribbon__hidden]: !Levels[level]?.title })}
      style={{ fontSize: isMobile ? 12 : undefined }}
      text={Levels[level]?.title}
      color={Levels[level]?.color}
    >
      {isGrammar ? (
        <Collapse
          size={"large"}
          defaultActiveKey={0}
          //collapsible={items.length ? undefined : "disabled"}
          className={styles.collapseTitle}
          items={[{ key: 0, label: description, children: <Timeline items={items} mode={"left"} /> }]}
        />
      ) : (
        <>
          <div className={styles.title}>
            {description}
            {!isActive && <Tag color={"red"}>inactive</Tag>}
          </div>
          <Timeline items={items} mode={"left"} />
        </>
      )}
    </Badge.Ribbon>
  );
};

export default LessonsModule;
