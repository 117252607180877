import ReactPlayer from "react-player";
import React, { FC, PropsWithChildren, useCallback, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import styles from "./VideoPlayer.module.scss";
import { OnProgressProps } from "react-player/base";
import { PlaySquareOutlined } from "@ant-design/icons";
import { MediaType } from "App.types";

type Props = {
  text?: string;
  clip: Partial<MediaType>;
  onFinish: Function;
  isPlaying: boolean;
  withFullscreen: boolean | undefined;
  url: any;
  allVideosPlayed: boolean;
};

const VideoPlayerItem: FC<Props & PropsWithChildren> = ({ allVideosPlayed, withFullscreen, text, isPlaying, clip, onFinish, url }) => {
  const [duration, setDuration] = useState<number>();
  const [fullScreen, setFullScreen] = useState(false);
  const [end, setEnd] = useState<number | undefined>();

  const playerRef = useRef<ReactPlayer>(null);

  const { start = 0, layout } = clip;

  useEffect(() => {
    playerRef.current?.seekTo(start);
    setEnd(clip.end);
  }, [clip.end, start, allVideosPlayed]);

  const onProgress = useCallback(
    (s: OnProgressProps) => {
      if (end && s.playedSeconds >= end) {
        setEnd(undefined);
        onFinish();
      }
    },
    [end, onFinish],
  );

  useEffect(() => {
    const player = playerRef.current?.getInternalPlayer();
    if (player?.textTracks?.length) {
      [...player.textTracks].forEach((track: any) => (track.mode = "disabled"));
    }
    if (player && duration && text?.trim()) {
      let text1 = player.addTextTrack("captions", "English", "en");
      text1.mode = "showing";
      text1.addCue(new VTTCue(0, 99, text));
    }
  }, [duration, text]);

  const containerRef = useRef<HTMLDivElement>(null);

  const onClick = () => {
    if (fullScreen) {
      setFullScreen(false);
      document.exitFullscreen && document.exitFullscreen();
    } else {
      if (layout === "vertical" && withFullscreen && isMobile && containerRef.current?.requestFullscreen)
        containerRef.current.requestFullscreen().then(() => setFullScreen(true));
    }
  };

  return (
    <div
      ref={containerRef}
      onClick={onClick}
      style={{
        //background: duration && !isMobile && withBackground ? "black" : undefined,
        height: isMobile ? "" : "100%",
        position: "relative",
      }}
    >
      <ReactPlayer
        progressInterval={100}
        onProgress={onProgress}
        playsinline={!(layout === "vertical" && withFullscreen && isMobile)}
        ref={playerRef}
        onDuration={setDuration}
        onError={(e) => {
          console.log("error", e);
          onFinish();
        }}
        onEnded={() => {
          setEnd(undefined);
          onFinish();
          if (fullScreen && document.exitFullscreen) {
            try {
              document.exitFullscreen().then(() => setFullScreen(false));
            } catch (e) {}
          }
        }}
        playing={isPlaying}
        // onReady={() => playerRef?.current?.seekTo(0)}
        width={isMobile ? "100%" : ""}
        height={isMobile ? (fullScreen || (clip.layout === "vertical" && !withFullscreen) ? "100%" : 240) : 360}
        controls={false}
        url={url}
      />
      {!isPlaying && <PlaySquareOutlined className={styles.btnPlay} />}
    </div>
  );
};

export default VideoPlayerItem;
