import { FC, useRef, useState } from "react";
import styles from "./PushAudioRecorder.module.scss";
import { AudioOutlined } from "@ant-design/icons";

const PushAudioRecorder: FC<{ setBlob: Function; setLoading: Function; setText?: (s: string) => any }> = ({
  setText,
  setLoading,
  setBlob,
}) => {
  const [isRecording, setIsRecording] = useState(false);

  const mediaStream = useRef<MediaStream>();
  const mediaRecorder = useRef<MediaRecorder>();
  const chunks = useRef<Blob[]>([]);

  const startRecording = async () => {
    setIsRecording(true);

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaStream.current = stream;
      mediaRecorder.current = new MediaRecorder(stream);
      mediaRecorder.current.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunks.current.push(e.data);
        }
      };
      mediaRecorder.current.onstop = () => {
        const recordedBlob = new Blob(chunks.current, { type: "audio/mpeg" });
        if (recordedBlob) {
          setBlob(recordedBlob);
          // const file = new File([recordedBlob], "record");
          //
          // const form = new FormData();
          // form.append("file", file);
          // setLoading(true);
          //  API.practice
          //    .speech(form)
          //    .then(setText)
          //    .catch(() => setLoading(false));
        }

        chunks.current = [];
      };
      mediaRecorder.current.start();
    } catch (error) {
      setIsRecording(false);
      console.error("Error accessing microphone:", error);
    }
  };

  const stopRecording = () => {
    setIsRecording(false);

    if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
      mediaRecorder.current.stop();
    }
    if (mediaStream.current) {
      mediaStream.current.getTracks().forEach((track) => {
        track.stop();
      });
    }
  };

  const onMouseDown = () => {
    startRecording();
  };

  const onMouseUp = () => {
    stopRecording();
  };

  return (
    <div onMouseDown={onMouseDown} onMouseUp={onMouseUp} className={styles.pushAudioRecorder} data-recording={isRecording}>
      <AudioOutlined style={{ fontSize: 32, color: "white" }} />
    </div>
  );
};
export default PushAudioRecorder;
