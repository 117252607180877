import { Editable, ReactEditor, Slate, withReact } from "slate-react";
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { CustomText, StatusType, TaskType, UserProp } from "App.types";
import { HistoryEditor, withHistory } from "slate-history";
import { BaseEditor, BaseElement, createEditor, Transforms } from "slate";
import { Button } from "antd";
import API from "Api";
import cx from "classnames";
import { withCustomLogic } from "App.helpers";
import { CloseOutlined } from "@ant-design/icons";
import SentenceMaskedLeaf from "Components/SentenceTask/SentenceLeaf";
import { SentenceTaskProps } from "Components/SentenceTask/SentenceTask.type";
import styles from "Components/SentenceTask/SentenceTask.module.scss";
import TaskPanel from "Components/TaskPanel";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { NotifyContext, UserContext } from "App";
import { usePressEnter } from "Components/SentenceTask/Helpers/usePressEnter";

const initialValue = [{ children: [{ text: "" }] }];

export type CustomElement = { children: CustomText[] } & BaseElement;

declare module "slate" {
  export interface CustomTypes {
    Editor: ReactEditor & BaseEditor & HistoryEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

const FeedbackTextTask: FC<SentenceTaskProps> = ({
  sentence,
  sentence: { id, text, translate, description, storyId, markers },
  lesson,
  onTaskComplete,
  onNext,
  children,
  alignCenter = false,
  noTranslate,
  showGrammar,
}) => {
  const [status, setStatus] = useState<StatusType>(StatusType.Editing);
  const [activeLeaf, setActiveLeaf] = useState<number | null>(null);
  const [isSubmitting, setSubmitting] = useState(false);

  const editor = useMemo(() => withReact(withHistory(withCustomLogic(createEditor()))), []);

  const notifyApi = useContext(NotifyContext);
  const user = useContext(UserContext);

  const { data: userProps } = useSuspenseQuery({
    queryKey: ["props", user?.id],
    queryFn: API.user.getProps,
    staleTime: Infinity,
  });

  const isTemplate = markers.includes("template");
  const propName = isTemplate ? markers.find((m) => m !== "template" && m.toUpperCase() === m) : undefined;

  const defaultText = useMemo(
    () => (isTemplate ? userProps.find((p) => p.name === propName)?.value : ""),
    [isTemplate, propName, userProps],
  );

  const setInitialState = useCallback(
    (withFocus: boolean = true) => {
      editor.children.forEach(() => {
        Transforms.delete(editor, { at: [0] });
      });

      editor.children = [];

      Transforms.insertNodes(editor, [{ children: [{ text: defaultText ?? "" }] }], { select: withFocus });
      if (withFocus) {
        try {
          ReactEditor.focus(editor);
        } catch (e) {}
      }
    },
    [defaultText, editor],
  );

  const client = useQueryClient();

  const onComplete = useCallback(() => {
    setStatus(StatusType.Completed);
    return onTaskComplete();
  }, [onTaskComplete]);

  const onSubmit = useCallback(async () => {
    const answer = editor.children[0].children[0].text.trim();

    if (!answer && isTemplate) {
      return notifyApi.warning({ message: "Напиши что-нибудь!" });
    }

    if (propName) {
      setSubmitting(true);
      try {
        await API.user.updateProp(propName, answer);
      } finally {
        setSubmitting(false);
      }
      await client.invalidateQueries({ queryKey: ["props", user?.id] });
      client.setQueryData(
        ["props", user?.id],
        (props: UserProp[]) => props?.map((p) => ({ ...p, value: p.name === propName ? answer : p.value })),
      );
    }

    if (answer) {
      await API.feedback.save({
        answer,
        question: text || translate || description || "",
        lessonId: lesson.id,
        sentenceId: id,
        type: "taskFeedback",
        task: "text",
      });
    }
    onComplete();
  }, [editor.children, isTemplate, propName, onComplete, notifyApi, client, user?.id, text, translate, description, lesson.id, id]);

  // initial
  useEffect(() => {
    setActiveLeaf(null);
    setStatus(StatusType.Editing);
    setInitialState(true);
  }, [sentence.id, editor, setInitialState]);

  const renderLeaf = useCallback(
    (props: any) => (
      <SentenceMaskedLeaf
        underlined={props.leaf.audioIdx === (activeLeaf ?? -1)}
        showErrors={!["editing", "completed", "loading", "isRecording", ""].includes(status)}
        {...props}
      />
    ),
    [status, activeLeaf],
  );

  const onReset = () => {
    setInitialState(status === StatusType.Editing);

    editor.children.forEach(() => {
      Transforms.delete(editor, { at: [0] });
    });

    editor.children = [];

    Transforms.insertNodes(editor, [{ children: [{ text: "" }] }], { select: true });
  };

  usePressEnter({ onCheck: onSubmit, onNext, isCompleted: status === StatusType.Completed });

  return (
    <div className={styles.sentenceTask}>
      <div className={cx(styles.content, { [styles.content_hasChildren]: !!children })}>
        <div className={styles.children}>{children}</div>

        <div className={cx(styles.slate, { [styles.slate_alignCenter]: alignCenter })}>
          <div className={styles.slate_wrapper}>
            <form spellCheck="false">
              <Slate editor={editor} initialValue={initialValue}>
                <Editable
                  className={styles.textArea}
                  readOnly={status === "completed"}
                  onKeyDown={() => setStatus(StatusType.Editing)}
                  renderLeaf={renderLeaf}
                  onChange={console.log}
                  //renderElement={(props) => <StoryElement isActive={activeSent === props.element.id} play={play} {...props} />}
                />
              </Slate>
            </form>
            {status !== "completed" && (
              <Button
                size={"small"}
                type={"text"}
                className={styles.btn__clear}
                icon={<CloseOutlined style={{ fontSize: 12 }} />}
                onClick={onReset}
              />
            )}
          </div>
          {!noTranslate && <div className={styles.translate}>{translate}</div>}
        </div>
      </div>

      <TaskPanel
        lessonId={lesson.id}
        task={TaskType.FeedbackText}
        sentId={id}
        storyId={storyId}
        showGrammar={showGrammar}
        onNext={onNext}
        isCompleted={status === StatusType.Completed}
      >
        <Button loading={isSubmitting} type={"primary"} shape={"round"} onClick={onSubmit}>
          ответить
        </Button>
      </TaskPanel>
    </div>
  );
};

export default FeedbackTextTask;
