import styles from "./TaskPanel.module.scss";
import React, { FC, PropsWithChildren } from "react";
import { Flex, FlexProps } from "antd";
import cx from "classnames";
import { useLocation } from "react-router-dom";
import { isIOS } from "react-device-detect";

const TaskPanelBasic: FC<FlexProps & PropsWithChildren> = ({ children, justify = "space-between", ...rest }) => {
  const location = useLocation();
  const isReview = location.pathname.includes("/review/");

  return (
    <div className={cx(styles.taskPanel, [{ [styles.review]: isReview, [styles.taskPanel_ios]: isIOS }])} {...rest}>
      <Flex justify={justify} align={"center"} className={styles.content}>
        {children}
      </Flex>
    </div>
  );
};

export default TaskPanelBasic;
