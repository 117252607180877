import ReactPlayer from "react-player";
import { isMobile } from "react-device-detect";
import { SkipTags, STATIC_URL } from "App.constants";
import { encodeS3URI } from "App.helpers";
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { StoryType, TranscriptType } from "App.types";
import styles from "./VideoPlayerStudio.module.scss";
import { useQuery } from "@tanstack/react-query";
import API from "Api";
import { groupBy, min, orderBy } from "lodash";
import { PlaySquareOutlined } from "@ant-design/icons";

import "./styles.css";

const VideoPlayerStudio: FC<{ story: StoryType; showSubtitles: boolean }> = ({ story, showSubtitles }) => {
  const [duration, setDuration] = useState<number>();
  const [isPlaying, setIsPlaying] = useState(true);

  const { data: transcripts = [] } = useQuery({
    staleTime: Infinity,
    queryKey: ["transcripts", story.id],
    enabled: !!story.title,
    queryFn: () => API.transcriptions.getByStoryId(story.id),
    select: (data) => orderBy(data, "id"),
  });

  // const { data: videoBlob, isLoading } = useQuery({
  //   queryKey: ["video", story?.title],
  //   queryFn: story
  //     ? () =>
  //         axios
  //           .get(`${STATIC_URL}/stories/${encodeS3URI(story?.title)}/video.mp4`, {
  //             headers: { Accept: "video/mp4;charset=UTF-8" },
  //             responseType: "blob",
  //           })
  //           .then((resp) => resp.data)
  //     : undefined,
  // });

  // const videoData = useMemo(() => (videoBlob ? URL.createObjectURL(new Blob([videoBlob],{ type: "video/mp4" })):undefined), [videoBlob]);

  const playerRef = useRef<ReactPlayer>(null);

  const groupedTrans = useMemo<{ [key: number]: TranscriptType[] }>(() => groupBy(transcripts, "sent"), [transcripts]);

  useEffect(() => {
    const player = playerRef.current?.getInternalPlayer();

    if (player && duration && transcripts.length && showSubtitles) {
      for (const track of player.textTracks) {
        track.mode = "hidden";
      }
      const textTrack = player.addTextTrack("captions", "English", "en");
      textTrack.mode = "showing";

      transcripts.forEach((t, idx) => {
        const trRow = groupedTrans[t.sent ?? 0];
        const text = trRow
          .map((tr) => (tr.start === t.start && tr.text.trim() && !SkipTags.includes(tr.text.trim()) ? `<u>${tr.text}</u>` : tr.text))
          .join("")
          .trim();

        // @ts-ignore
        textTrack.addCue(new VTTCue(t.start, min([t.end, transcripts[idx + 1]?.start]), ` ${text} `));
      });
    } else if (player?.textTracks?.length) {
      player.textTracks[0].mode = "disabled";
    }
  }, [duration, transcripts, groupedTrans, showSubtitles]);

  const isShortVideo = !!duration && duration < 20;

  const onClick = useCallback(() => {
    if (isShortVideo) {
      setIsPlaying((prev) => !prev);
    }
  }, [isShortVideo]);

  return (
    <div className={styles.videoPlayerStudio} onClick={onClick}>
      <ReactPlayer
        ref={playerRef}
        height={isMobile ? "100%" : 360}
        width={isMobile ? "100%" : undefined}
        controls={!isShortVideo}
        playing={isPlaying}
        onDuration={setDuration}
        onEnded={() => setIsPlaying(false)}
        onPause={() => setIsPlaying(false)}
        onStart={() => setIsPlaying(true)}
        playsinline={true}
        url={`${STATIC_URL}/stories/${encodeS3URI(story.title)}/video.mp4`}
      />
      {!isPlaying && isShortVideo && <PlaySquareOutlined className={styles.btnPlay} />}
    </div>
  );
};

export default React.memo(VideoPlayerStudio);
