import { useCallback, useContext } from "react";
import { StatusType, TagType } from "App.types";
import { getCompletedSlate } from "App.helpers";
import { Editor, Transforms } from "slate";
import { successMessage } from "App.constants";
import { NotifyContext } from "App";

type Props = {
  text?: string;
  setStatus: Function;
  editor: any;
  tags: TagType[];
  onTaskComplete: Function;
  showSuccessMessage?: boolean;
  answer?: string;
};

export const useOnComplete = ({ text, answer, setStatus, editor, tags, showSuccessMessage = true, onTaskComplete }: Props) => {
  const notifyApi = useContext(NotifyContext);

  return useCallback(
    (showMessage = showSuccessMessage) => {
      setStatus(StatusType.Completed);

      if (answer) {
        const newTextAreaData = answer ? [{ text: answer }] : getCompletedSlate(tags, text ?? "");

        Transforms.select(editor, {
          anchor: Editor.start(editor, []),
          focus: Editor.end(editor, []),
        });
        Transforms.removeNodes(editor);
        Transforms.insertNodes(editor, [{ children: newTextAreaData }]);
      }

      if (showMessage) {
        notifyApi?.success(successMessage);
      }

      onTaskComplete(text);
    },
    [text, answer, showSuccessMessage, setStatus, tags, editor, onTaskComplete, notifyApi],
  );
};
