import { SentenceType, StatusType } from "App.types";
import React, { FC, useEffect, useState } from "react";
import RecordButton from "Components/RecordButton";
import AnimateFade from "Components/AnimateFade";
import { Button, Flex, Popconfirm } from "antd";
import { AudioMutedOutlined } from "@ant-design/icons";
import { useSpeechCheckRead } from "Hooks/useSpeechCheckRead";
import { SpeechResultType } from "Hooks/useDeepgramAlt";
import styles from "./DialogTaskTranslate.module.scss";
import cx from "classnames";

const DialogTaskRead: FC<{
  onClickRecording: () => any;
  audioStatus: StatusType;
  sentence: SentenceType;
  onSuccess: Function;
  storyId?: number;
  speechResults: SpeechResultType[];
  isSpeaking: boolean;
  voice?: string;
}> = ({ speechResults, onClickRecording, sentence: { isLeft, text, tags }, sentence, onSuccess, audioStatus, isSpeaking }) => {
  const [taskStatus, setTaskStatus] = useState<StatusType>(StatusType.Empty);

  const { completedTagIdx, setCompletedTagIdx } = useSpeechCheckRead({
    enabled: taskStatus !== StatusType.Completed,
    speechResults,
    sentence,
  });

  useEffect(() => {
    if (taskStatus !== StatusType.Completed && completedTagIdx >= tags.length - 1) {
      setTaskStatus(StatusType.Completed);
      onSuccess(sentence.id);
    }
  }, [completedTagIdx, onSuccess, sentence.id, tags.length, taskStatus]);

  return (
    <div className={styles.dialogTaskTranslate}>
      <Flex gap={5} align={"center"} style={{ flexDirection: isLeft ? "row-reverse" : undefined }}>
        <AnimateFade hide={taskStatus === StatusType.Completed}>
          <RecordButton
            onClick={() => {
              setCompletedTagIdx(-1);
              onClickRecording();
            }}
            isRecording={audioStatus === StatusType.IsRecording}
            loading={audioStatus === StatusType.Loading}
            isSpeaking={isSpeaking}
          />
          <Popconfirm
            title={"Пропустить задание?"}
            onConfirm={() => {
              setTaskStatus(StatusType.Completed);
              onSuccess(sentence.id);
            }}
          >
            <Button icon={<AudioMutedOutlined />} type={"text"} />
          </Popconfirm>
        </AnimateFade>{" "}
        <div className={cx(styles.message, { [styles.message__left]: isLeft })}>
          <div className={styles.text}>
            {tags.map((tag, idx) => (
              <span
                className={cx(styles.tag, {
                  [styles.tag__active]: completedTagIdx < idx,
                  [styles.tag__completed]: true,
                  [styles.tag__merged]: idx && text.includes(`${tags[idx - 1].word}${tag.word}`),
                })}
                key={`${idx}${tag.word}`}
              >
                {tag.word}
              </span>
            ))}
          </div>
        </div>
        {/*<StoryDialogSentence*/}
        {/*  completedTagIdx={completedTagIdx}*/}
        {/*  storyId={storyId}*/}
        {/*  audio={audio}*/}
        {/*  sentence={sentence}*/}
        {/*  text={text}*/}
        {/*  tags={tags}*/}
        {/*  active={taskStatus !== StatusType.Completed}*/}
        {/*  voice={voice}*/}
        {/*/>*/}
      </Flex>
    </div>
  );
};

export default DialogTaskRead;
