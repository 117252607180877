import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { StatusType, TaskType } from "App.types";
import { Button } from "antd";
import API from "Api";
import cx from "classnames";
import { SentenceTaskProps } from "Components/SentenceTask/SentenceTask.type";
import styles from "Components/SentenceTask/SentenceTask.module.scss";
import TaskPanel from "Components/TaskPanel";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { NotifyContext, UserContext } from "App";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import "./FeedbackCalendarTask.css";

const FeedbackCalendarTask: FC<SentenceTaskProps> = ({
  sentence: { id, text, translate, description, storyId, markers },
  lesson,
  onTaskComplete,
  onNext,
  children,
  alignCenter = false,
  noTranslate,
  showGrammar,
}) => {
  const [selectedDate, setSelectedDate] = useState<any>();
  const [status, setStatus] = useState<StatusType>(StatusType.Editing);

  const notifyApi = useContext(NotifyContext);

  const user = useContext(UserContext);

  const { data: userProps } = useSuspenseQuery({
    queryKey: ["props", user?.id],
    queryFn: API.user.getProps,
    staleTime: Infinity,
  });

  const isTemplate = markers.includes("template");
  const propName = isTemplate ? markers.find((m) => m !== "template") : undefined;

  useEffect(() => {
    if (isTemplate) {
      const propValue = userProps.find((p) => p.name === propName)?.value;
      if (propValue) {
        setSelectedDate(propValue);
      }
    }
  }, [isTemplate, propName, userProps]);

  const client = useQueryClient();

  const onComplete = useCallback(() => {
    setStatus(StatusType.Completed);
    onTaskComplete();
  }, [onTaskComplete]);

  const onSubmit = useCallback(async () => {
    const answer = selectedDate;

    if (!answer && isTemplate) {
      return notifyApi.warning({ message: "Напиши что-нибудь!" });
    }

    if (propName && answer) {
      await API.user.updateProp(propName, answer);
      client.invalidateQueries({ queryKey: ["props", user?.id] });
    }

    if (answer) {
      await API.feedback.save({
        answer,
        question: text || translate || description || "",
        lessonId: lesson.id,
        sentenceId: id,
        type: "taskFeedback",
        task: "text",
      });
    }
    onComplete();
  }, [selectedDate, isTemplate, propName, onComplete, notifyApi, client, user?.id, text, translate, description, lesson.id, id]);

  return (
    <div className={styles.sentenceTask}>
      <div className={cx(styles.content, { [styles.content_hasChildren]: !!children })}>
        <div className={styles.children}>{children}</div>

        <div className={cx(styles.slate, { [styles.slate_alignCenter]: alignCenter })}>
          <div className={styles.slate_wrapper}>
            <form spellCheck="false">
              <DatePicker
                value={selectedDate}
                calendarProps={{ className: styles.calendar, defaultView: "decade", defaultValue: "01/01/2000" }}
                onChange={setSelectedDate}
              />
            </form>
          </div>
          {!noTranslate && <div className={styles.translate}>{translate}</div>}
        </div>
      </div>

      <TaskPanel
        lessonId={lesson.id}
        task={TaskType.FeedbackText}
        sentId={id}
        storyId={storyId}
        showGrammar={showGrammar}
        onNext={onNext}
        isCompleted={status === StatusType.Completed}
      >
        <Button type={"primary"} shape={"round"} onClick={onSubmit}>
          ответить
        </Button>
      </TaskPanel>
    </div>
  );
};

export default FeedbackCalendarTask;
