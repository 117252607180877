import { useQuery } from "@tanstack/react-query";
import { SentenceType, TaskType } from "App.types";
import { DEFAULT_VOICES, SkipTags } from "App.constants";
import API from "Api";

export const useTranscripts = ({
  sentence,
  audioIndex = 0,
  voice = !audioIndex ? sentence?.voice || DEFAULT_VOICES[0] : DEFAULT_VOICES[1],
}: {
  sentence: SentenceType | undefined;
  audioIndex?: number;
  style?: string;
  voice?: string;
}) => {
  // const style = sentence?.voiceStyle && (voice.includes("DragonHD") || AZURE_VOICES.includes(voice)) ? sentence.voiceStyle : "";

  const { data } = useQuery({
    enabled: !!sentence?.text?.trim() && sentence.task !== TaskType.Grammar,
    queryKey: ["transcript", sentence?.id, voice],
    queryFn: () => (sentence ? API.speech.getJson(sentence?.id, voice) : undefined),
    staleTime: Infinity,
    select: (data) => data?.filter((tr) => !SkipTags.includes(tr.text)),
  });

  return data;
};
